import React, {useCallback, useEffect, useState} from 'react';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@mui/material";
import {FaSpinner} from "react-icons/all";
import {useHistory} from "react-router-dom";
import Footer from "../Footer/Footer";
import {getRequest, postRequest} from "../../utils/APICall";
import {GET_ASSESSMENT_SKILLS_VALUES_STATEMENTS_BY_RATING, MANAGE_ASSESSMENT_STATEMENT} from "../../Constants";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const AssessmentSkillValueStatement = (props) => {
    const history = useHistory()
    const value_id = localStorage.getItem('value_id');
    if (!value_id) {
        history.push('assessment-skills-values');
    }
    const classes = useStyles();

    const [loader, setLoader] = useState(false);
    const [visible, setVisible] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [updatedStatement, setUpdatedStatement] = useState("");
    const [statements, setStatements] = useState([]);
    const [newStatement, setNewStatement] = useState(false);

    const getStatements = useCallback(async (value_id) => {
        setLoader(true)
        const statementResponse = await getRequest(props, GET_ASSESSMENT_SKILLS_VALUES_STATEMENTS_BY_RATING, "value_id=" + value_id)
        setStatements(statementResponse.data)
        setLoader(false)
    }, [props]);

    useEffect(() => {
        getStatements(value_id).catch(() => {})
    }, [value_id, getStatements]);

    const viewStatement = (index, statement) => {
        setEditIndex(index)
        setVisible(true)
        setUpdatedStatement(statement)
    }

    const updateStatement1 = (e) => {
        setUpdatedStatement(e.target.value)
    }

    const saveStatement = async (statementId = 0) => {
        const post_params = {
            statement_id: statementId,
            statement: updatedStatement,
            assessment_value_id: parseInt(value_id)
        }
        setLoader(true);
        await postRequest(props, MANAGE_ASSESSMENT_STATEMENT, post_params);
        setLoader(false);
        await getStatements(value_id);

        setEditIndex(-1)
        setVisible(false);
        setNewStatement(false);
        setUpdatedStatement("")
        setLoader(false)
    }

    const openNewNewStatement = () => {
        setNewStatement(true)
        setUpdatedStatement("")
    }

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card">
                                    <div
                                        className="d-flex flex-row bd-highlight mb-3 justify-content-between align-items-center">
                                        <h6 className="card-title card-padding pb-0 mb-5"><b>Assessment Skill Value
                                            Statements</b></h6>
                                        <button className="btn btn-info"
                                                onClick={openNewNewStatement}
                                        >
                                                <span className="material-icons" style={{margin: "5%"}}>
                                                    add
                                                </span>
                                        </button>
                                    </div>
                                    <div
                                        className={`d-flex flex-row bd-highlight mb-3 justify-content-evenly ${(newStatement) ? '' : 'DisplayNone'}`}>
                                        <div className="mt-2">
                                            <b>-</b>
                                        </div>

                                        <div className="mt-2 w-50 text-left">
                                            <textarea className="form-control" onChange={updateStatement1}/>
                                            <Button type="button"
                                                    className="mdc-button mdc-button--raised w-40 mt-2"
                                                    variant="outlined" disabled={loader}
                                                    onClick={() => saveStatement(0)}>
                                                {loader && (
                                                    <FaSpinner/>
                                                )}
                                                {loader && <span>&nbsp;Loading...</span>}
                                                {!loader && <span>Save</span>}
                                            </Button>
                                            <Button type="button"
                                                    className="mdc-button ml-2 w-40 mt-2"
                                                    variant="outlined" disabled={loader}
                                                    onClick={() => {
                                                        setUpdatedStatement("")
                                                        setNewStatement(false)
                                                    }}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>

                                    {
                                        statements.map((statement, index) => (
                                            <div
                                                className="d-flex flex-row bd-highlight mb-3 justify-content-evenly">
                                                <div className="mt-2">
                                                    <b>{index + 1}</b>
                                                </div>
                                                <div className="mt-2 w-50 text-left">
                                                    <h5 onClick={() => viewStatement(index, statement.statement)}
                                                        style={{display: (!visible || editIndex !== index) ? '' : 'none'}}>{statement.statement}</h5>
                                                    <div
                                                        style={{display: (visible && editIndex === index) ? '' : 'none'}}>
                                                        <textarea className="form-control"
                                                                  onChange={updateStatement1}>{statement.statement}</textarea>
                                                        <Button type="button"
                                                                className="mdc-button mdc-button--raised w-40 mt-2"
                                                                variant="outlined" disabled={loader}
                                                                onClick={() => saveStatement(statement.id)}>
                                                            {loader && (
                                                                <FaSpinner/>
                                                            )}
                                                            {loader && <span>&nbsp;Loading...</span>}
                                                            {!loader && <span>Save</span>}
                                                        </Button>
                                                        <Button type="button"
                                                                className="mdc-button ml-2 w-40 mt-2"
                                                                variant="outlined" disabled={loader}
                                                                onClick={() => {
                                                                    setUpdatedStatement("")
                                                                    setVisible(false)
                                                                }
                                                                }>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>
}

export default AssessmentSkillValueStatement;
