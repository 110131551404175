import React, {useState} from 'react';
import {Button} from "@material-ui/core";
import Sidebar from "../Sidebar/Sidebar";
import TextField from "@material-ui/core/TextField";
import {useFormik} from "formik";
import {FaSpinner} from "react-icons/all";
import {checkLogin} from "../../App";
import ChangePasswordSchema from "../../validations/ChangePasswordSchema";
import Header from "../Header/Header";
import {postRequest} from "../../utils/APICall";
import {CHANGE_PASSWORD} from "../../Constants";
import Footer from "../Footer/Footer";

const ChangePassword = (props) => {
    if (!checkLogin()) {
        props.history.push('/');
    }
    const [loading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);

    const inputHandler = (e) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'oldPassword':
                setOldPassword(value);
                break;
            case 'newPassword':
                setNewPassword(value);
                break
            default:
                setConfirmPassword(value);
                break;
        }
    }

    const submitHandler = async (values) => {
        setLoading(true);
        const passwordData = {
            old_password: values.oldPassword,
            new_password: values.newPassword
        }
        await postRequest(props, CHANGE_PASSWORD, passwordData);
        setLoading(false);
        props.history.push('logout');
    }

    const formik = useFormik({
        initialValues: {
            'oldPassword': oldPassword,
            'newPassword': newPassword,
            'confirmPassword': confirmPassword
        },
        validationSchema: ChangePasswordSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            submitHandler(values);
        }
    });

    return <>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div
                                className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                            <div
                                className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet w-100">
                                <div className="mdc-card">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h1>Change Password</h1>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <TextField id="outlined-basic"
                                                           className="mdc-text-field__input"
                                                           label="Current Password" type="password" name="oldPassword"
                                                           variant="outlined"
                                                           onChange={(e) => {
                                                               formik.handleChange(e);
                                                               inputHandler(e);
                                                           }}
                                                           value={formik.values.oldPassword}
                                                           error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                                                           helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <TextField id="outlined-basic"
                                                           className="mdc-text-field__input"
                                                           label="New Password" type="password" name="newPassword"
                                                           variant="outlined"
                                                           onChange={(e) => {
                                                               formik.handleChange(e);
                                                               inputHandler(e);
                                                           }}
                                                           value={formik.values.newPassword}
                                                           error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                                           helperText={formik.touched.newPassword && formik.errors.newPassword}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <TextField id="outlined-basic"
                                                           className="mdc-text-field__input"
                                                           label="Confirm Password" type="password"
                                                           name="confirmPassword"
                                                           variant="outlined"
                                                           onChange={(e) => {
                                                               formik.handleChange(e);
                                                               inputHandler(e);
                                                           }}
                                                           value={formik.values.confirmPassword}
                                                           error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                                           helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <Button type="submit"
                                                        className="mdc-button mdc-button--raised w-100"
                                                        variant="outlined" disabled={loading}>
                                                    {loading && (
                                                        <FaSpinner/>
                                                    )}
                                                    {loading && <span>&nbsp;Loading...</span>}
                                                    {!loading && <span>Change Password</span>}
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div
                                className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>
}

export default ChangePassword;
