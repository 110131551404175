import * as Yup from 'yup';

const CreateEvaluatorSchema = Yup.object().shape({
    first_name: Yup.string()
        .required('First name field can not be blank.!'),
    last_name: Yup.string()
        .required('Last name field can not be blank.!'),
    email: Yup.string()
        .required('Email field can not be blank.!'),
});

export default CreateEvaluatorSchema;
