import React from 'react';
import {useHistory} from "react-router-dom";

const Logout = () => {
    let history = useHistory();

    localStorage.removeItem('user-info');
    history.push('login');
    return <></>
}
export default Logout;
