import * as Yup from 'yup';

const AddNewAssessmentSkillSchema = Yup.object().shape({
    player_position_id: Yup.string()
        .required('You must select a player position.!'),
    category_id: Yup.string()
        .required('You must select a assessment category.!'),
    skill_name: Yup.string()
        .required('Skill name field can not be blank.!')
});

export default AddNewAssessmentSkillSchema;
