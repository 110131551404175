import React, {useCallback, useEffect, useState} from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {DataGrid} from "@material-ui/data-grid";
import {getRequest, postRequest} from "../../utils/APICall";
import {ADD_PLAYER_POSITIONS, GET_PLAYER_POSITIONS} from "../../Constants";
import {makeStyles} from '@material-ui/core/styles';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import {FaSpinner} from "react-icons/all";
import {useFormik} from "formik";
import AddNewPositionSchema from "../../validations/AddNewPositionSchema";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const Position = (props) => {
    const classes = useStyles();

    const initialPosition = {
        'position_name': '',
        'short_name': ''
    }

    const [position, setPosition] = useState([]);
    const [loader, setLoader] = useState(false);
    const [openNewPositionPopup, setOpenNewPositionPopup] = useState(false);
    const [newPosition, setNewPosition] = useState(initialPosition);
    const [loading, setLoading] = useState(false);

    const getPlayerPositions = useCallback(async () => {
        const response = await getRequest(props, GET_PLAYER_POSITIONS)
        setPosition(response.data);
        setLoader(false);
    }, [props]);

    useEffect(() => {
        setLoader(true);
        getPlayerPositions().catch(() => {});
    }, [getPlayerPositions]);

    const handleClose = () => {
        setOpenNewPositionPopup(false);
    };

    const columns = [
        {field: "id", headerName: "ID", width: 90},
        {
            field: "position_name",
            headerName: "Position name",
            textAlign: "center",
            width: 300
        },
        {
            field: "short_name",
            headerName: "Position Short Name",
            width: 300
        },
    ];

    const rows = [];
    if (position.length > 0) {
        position.map((value) => {
            rows.push({
                "id": value.id,
                "position_name": value.position_name,
                "short_name": value.short_name,
                // "allow_to_types": (value.allow_to_types) ? value.allow_to_types : "-",
                // "status": value.status
            });
            return value;
        });
    }

    const newPositionPopupHandler = () => {
        console.log("initialPosition==================", initialPosition);
        setOpenNewPositionPopup(true);
    }

    const submitHandler = async (values) => {
        setLoading(true);
        const newPositionData = {
            position_name: values.position_name,
            short_name: values.short_name,
        }
        postRequest(props, ADD_PLAYER_POSITIONS, newPositionData);

        await getPlayerPositions()
        setOpenNewPositionPopup(false);
        setNewPosition(initialPosition);
    }

    const inputHandler = (e) => {
        setNewPosition({...newPosition, [e.target.name]: e.target.value});
    }

    const formik = useFormik({
        initialValues: {
            'position_name': newPosition.position_name,
            'short_name': newPosition.short_name
        },
        validationSchema: AddNewPositionSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            submitHandler(values);
            inputHandler(values);
        }
    });

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <Dialog
                                    open={openNewPositionPopup}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <DialogTitle id="responsive-dialog-title">
                                        Add New Position
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="model_content">
                                            <form onSubmit={formik.handleSubmit}>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <TextField id="outlined-basic"
                                                                   className="mdc-text-field__input"
                                                                   name="position_name"
                                                                   label="Position Name"
                                                                   variant="outlined"
                                                                   InputLabelProps={{shrink: true}}
                                                                   onChange={(e) => {
                                                                       formik.handleChange(e);
                                                                       inputHandler(e);
                                                                   }}
                                                                   value={formik.values.position_name}
                                                                   error={formik.touched.position_name && Boolean(formik.errors.position_name)}
                                                                   helperText={formik.touched.position_name && formik.errors.position_name}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <TextField id="outlined-basic"
                                                                   className="mdc-text-field__input"
                                                                   name="short_name"
                                                                   label="Position Short Name"
                                                                   variant="outlined"
                                                                   InputLabelProps={{shrink: true}}
                                                                   onChange={(e) => {
                                                                       formik.handleChange(e);
                                                                       inputHandler(e);
                                                                   }}
                                                                   value={formik.values.short_name}
                                                                   error={formik.touched.short_name && Boolean(formik.errors.short_name)}
                                                                   helperText={formik.touched.short_name && formik.errors.short_name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-12">
                                                        <Button type="submit"
                                                                className="mdc-button mdc-button--raised w-100"
                                                                variant="outlined">
                                                            {loading && (
                                                                <FaSpinner/>
                                                            )}
                                                            {loading && <span>&nbsp;Loading...</span>}
                                                            {!loading && <span>Save Position</span>}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>

                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Player Positions</h6>
                                    <div style={{textAlign: 'right'}}>
                                        <button className="btn btn-success" onClick={newPositionPopupHandler}>Add New
                                            Position
                                        </button>
                                    </div>
                                    <div className="table-responsive mt-2">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            autoHeight
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>;
};
export default Position;
