import React from "react";
import './App.css';
import {BrowserRouter} from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from "./components/Router/Router";

export function checkLogin() {
    let user_info = localStorage.getItem('user-info')

    if (user_info) {
        user_info = JSON.parse(user_info);
        return user_info.type === 'developer' || user_info.type === 'admin';
    }
    return false;
}

toast.configure();

function App() {
    localStorage.setItem('sub-menu-hide', false)
    localStorage.setItem('sub-developer-menu-hide', false)
    return (
        <div className="App body-wrapper">
            <BrowserRouter basename="/">
                <div className="App">
                    <Router/>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
