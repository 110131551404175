import React, {useEffect, useState} from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {DataGrid} from "@material-ui/data-grid";
import {getRequest, postRequest} from "../../utils/APICall";
import {ADD_NEW_MODULE, GET_ALL_MODULES} from "../../Constants";
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import {FaSpinner} from "react-icons/all";
import {useFormik} from "formik";
import AddNewModuleSchema from "../../validations/AddNewModuleSchema";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const Module = (props) => {
    const classes = useStyles();

    const [module, setModule] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
         getRequest(props, GET_ALL_MODULES).then((response) => {
             setModule(response.data);
             setLoader(false);
         });
    }, [props]);

    const initialModule = {
        'module_name': '',
        'api_route': '',
    }

    const [openNewModulePopup, setOpenNewModulePopup] = useState(false);
    const [model, setModel] = useState([]);
    const [open, setOpen] = useState(false);
    const [newModule, setNewModule] = useState(initialModule);
    const [loading, setLoading] = useState(false);

    const showAssignTypes = (assignTypes) => {
        setModel(assignTypes);
        handleClickOpen(true);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenNewModulePopup(false);
    };

    const columns = [
        {field: "id", headerName: "ID", width: 90},
        {
            field: "moduleName",
            headerName: "UserTypes name",
            width: 300
        },
        {
            field: "apiRoute",
            headerName: "API Route",
            width: 300
        },
        {
            field: "allow_to_types",
            headerName: "Allow To APILog Types",
            width: 300,
            renderCell: (state) => {
                const allowedType = state.row.allow_to_types;
                return (<FormControl className={classes.formControl}>
                    <button className="btn btn-info" style={{fontSize: "small"}}
                            onClick={() => showAssignTypes(allowedType)}>
                        View Assigned Types
                    </button>
                </FormControl>)
            },
        }
    ];

    const rows = [];
    if (module.length > 0) {
        module.map((value) => {
            rows.push({
                "id": value.id,
                "moduleName": value.module_name,
                "apiRoute": value.api_route,
                "allow_to_types": (value.allow_to_types) ? value.allow_to_types : "-",
                // "status": value.status
            });
            return value;
        });
    }

    const newModulePopupHandler = () => {
        setOpenNewModulePopup(true);
    }

    const submitHandler = (values) => {
        setLoading(true);
        const newModuleData = {
            module_name: values.module_name,
            api_route: values.api_route,
        }
        postRequest(props, ADD_NEW_MODULE, newModuleData);

        setLoading(false);
        setOpenNewModulePopup(false);
        setNewModule(initialModule);
    }

    const inputHandler = (e) => {
        setNewModule({...newModule, [e.target.name]: e.target.value});
    }

    const formik = useFormik({
        initialValues: {
            'module_name': newModule.module_name,
            'api_route': newModule.api_route
        },
        validationSchema: AddNewModuleSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            submitHandler(values);
            inputHandler(values);
        }
    });

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <DialogTitle id="responsive-dialog-title">
                                        User Types having access
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="model_content">
                                            <div className="row mt-2" style={{
                                                display: "flex",
                                                justifyContent: (model.length > 3) ? 'start' : 'center',
                                                textAlign: "left"
                                            }}>
                                                {(!model.length) ? "Currently, This module's rights has not been given to any user type" : model.map((m, i) => (
                                                    <div className="col-4">
                                                        {(i + 1) + " - " + m.type_name}
                                                    </div>
                                                ))}
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>

                                <Dialog
                                    open={openNewModulePopup}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <DialogTitle id="responsive-dialog-title">
                                        Add New Module
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="model_content">
                                            <form onSubmit={formik.handleSubmit}>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <TextField id="outlined-basic"
                                                                   className="mdc-text-field__input"
                                                                   name="module_name"
                                                                   label="Module Name"
                                                                   variant="outlined"
                                                                   InputLabelProps={{shrink: true}}
                                                                   onChange={(e) => {
                                                                       formik.handleChange(e);
                                                                       inputHandler(e);
                                                                   }}
                                                                   value={formik.values.module_name}
                                                                   error={formik.touched.module_name && Boolean(formik.errors.module_name)}
                                                                   helperText={formik.touched.module_name && formik.errors.module_name}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <TextField id="outlined-basic"
                                                                   className="mdc-text-field__input"
                                                                   name="api_route"
                                                                   label="API Route"
                                                                   variant="outlined"
                                                                   InputLabelProps={{shrink: true}}
                                                                   onChange={(e) => {
                                                                       formik.handleChange(e);
                                                                       inputHandler(e);
                                                                   }}
                                                                   value={formik.values.api_route}
                                                                   error={formik.touched.api_route && Boolean(formik.errors.api_route)}
                                                                   helperText={formik.touched.api_route && formik.errors.api_route}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-12">
                                                        <Button type="submit"
                                                                className="mdc-button mdc-button--raised w-100"
                                                                variant="outlined" disabled={loading}>
                                                            {loading && (
                                                                <FaSpinner/>
                                                            )}
                                                            {loading && <span>&nbsp;Loading...</span>}
                                                            {!loading && <span>Save Module</span>}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>

                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Modules</h6>
                                    <div style={{textAlign: 'right'}}>
                                        <button className="btn btn-success" onClick={newModulePopupHandler}>Add New
                                            Module
                                        </button>
                                    </div>
                                    <div className="table-responsive mt-2">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            autoHeight
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>;
};
export default Module;
