import React, {useEffect, useState} from "react";
import {getRequest, putRequest} from "../../utils/APICall";
import {GET_ALL_MODULES, GET_ALL_USER_TYPES, UPDATE_PERMISSION} from "../../Constants";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const ChangePermission = (props) => {
    const classes = useStyles();
    const params = props.location.aboutProps;

    if (!params) {
        props.history.push('user-types');
    }

    const [loader, setLoader] = useState(false);
    const [modules, setModules] = useState([]);
    const [selected, setSelected] = useState([]);
    const [userTypes, setUserType] = useState([]);

    useEffect(() => {
        setLoader(true);
        async function fetchRequest() {
            return await getRequest(props, GET_ALL_MODULES, "type_id=" + params.type);
        }

        fetchRequest().then(response => {
            setModules(response.data.modules);
            setSelected(response.data.selected_ids);
            setLoader(false);

            getRequest(props, GET_ALL_USER_TYPES).then(response2 => {
                setUserType(response2.data);
            }).catch(() => {});
        }).catch(() => {});

    }, [props, params]);

    const checkHandler = (e) => {
        let selectedIds = selected;
        const checkBoxValue = parseInt(e.target.value);
        if (!e.target.checked) {
            selectedIds.splice(selected.indexOf(checkBoxValue), 1);
        } else {
            selectedIds.push(checkBoxValue)
        }
        setSelected(selectedIds);
    }

    const permissionUpdateHandler = async () => {
        setLoader(true);
        const permissionParam = {
            'type_id': params.type,
            'permissions': selected
        }

        putRequest(props, UPDATE_PERMISSION, permissionParam)
        setLoader(false);
        props.history.push('user-types');
    }

    const handleChange = async (e) => {
        setModules([]);
        console.log("selected value", e.target.value);

        setLoader(true);
        let response = await getRequest(props, GET_ALL_MODULES, "type_id=" + e.target.value);

        setModules(response.data.modules);
        setSelected(response.data.selected_ids);
        setLoader(false);
    }

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <h2>Change Permission</h2>

                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <InputLabel id="demo-simple-select-label" className="ml-4 mt-3"
                                        style={{overflow: "visible", textAlign: "center"}}>Inherit From:</InputLabel>
                            <FormControl fullWidth className="ml-3">
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // value={age}
                                    label="Age"
                                    displayEmpty
                                    onChange={handleChange}
                                    style={{minWidth: 100}}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        userTypes.map((userType) =>
                                                params.type !== userType.id && (
                                                    <MenuItem value={userType.id}>
                                                        {userType.type_name}
                                                    </MenuItem>
                                                )
                                        )
                                    }
                                </Select>
                            </FormControl>
                            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                <Grid container spacing={2} className="ml-3">
                                    {
                                        modules.map((module) => (
                                            <Grid item xs={12} sm={6} lg={6} xl={4}>
                                                <FormGroup>
                                                    <FormControlLabel control={
                                                        <Checkbox
                                                            defaultChecked={(module.is_selected === 1)}
                                                            value={module.id} onChange={checkHandler}/>}
                                                                      label={module.module_name + " (" + module.api_route + ")"}/>
                                                </FormGroup>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                                <button className="btn btn-success" onClick={permissionUpdateHandler}>Update
                                    Permission
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </>;
}

export default ChangePermission;
