import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {Button, Link} from "@material-ui/core";
import {postRequest} from "../../utils/APICall";
import {FORGOT_PASSWORD_API} from "../../Constants";
import {useHistory} from "react-router-dom";
import {FaSpinner} from "react-icons/all";
import {useFormik} from "formik";
import ForgotPasswordSchema from "../../validations/ForgotPasswordSchema";

const ForgotPassword = (props) => {
    let history = useHistory();
    let [forgotPassword, setForgotPassword] = useState({
        email: "",
        loading: false
    });

    const inputHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setForgotPassword({...forgotPassword, [name]: value});
    }

    const submitHandler = async () => {
        const formData = {
            email: forgotPassword.email
        }

        setForgotPassword({...forgotPassword, "loading": true});

        postRequest(props, FORGOT_PASSWORD_API, formData)

        setForgotPassword({...forgotPassword, "loading": false});
        history.push('/reset-password')
    }

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: ForgotPasswordSchema,
        onSubmit: (values) => {
            submitHandler();
        }
    });

    const {loading} = forgotPassword;
    return (
        <div className="body-wrapper">
            <div className="main-wrapper">
                <div className="page-wrapper full-page-wrapper d-flex align-items-center justify-content-center">
                    <main className="auth-page">
                        <div className="mdc-layout-grid">
                            <div className="mdc-layout-grid__inner">
                                <div
                                    className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                                <div
                                    className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet">
                                    <div className="mdc-card">
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="mdc-layout-grid">
                                                <div className="mdc-layout-grid__inner">
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                                        <TextField id="outlined-basic"
                                                                   className="mdc-text-field__input"
                                                                   name="email"
                                                                   label="username" variant="outlined"
                                                                   onChange={(e) => {
                                                                       formik.handleChange(e);
                                                                       inputHandler(e);
                                                                   }}
                                                                   value={formik.values.email}
                                                                   error={formik.touched.email && Boolean(formik.errors.email)}
                                                                   helperText={formik.touched.email && formik.errors.email}
                                                        />
                                                    </div>
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                                        <Button type="submit"
                                                                className="mdc-button mdc-button--raised w-100"
                                                                variant="outlined"
                                                                disabled={loading}>
                                                            {loading && (
                                                                <FaSpinner/>
                                                            )}
                                                            {loading && <span>&nbsp;Loading...</span>}
                                                            {!loading && <span>Get OTP</span>}
                                                        </Button>
                                                    </div>
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-6-desktop d-flex align-items-start justify-content-start">
                                                        <Link href="/login">
                                                            Back to login
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div
                                    className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
