import React, {useCallback, useEffect, useState} from 'react';
import {getRequest, postRequest} from "../../utils/APICall";
import {
    ADD_NEW_ASSESSMENT_CATEGORY,
    CHANGE_ASSESSMENT_CATEGORIES_STATUS,
    GET_ASSESSMENT_CATEGORIES,
    GET_PLAYER_POSITIONS
} from "../../Constants";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import {DataGrid} from "@material-ui/data-grid";
import Footer from "../Footer/Footer";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import {Button, InputLabel} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import TextField from "@material-ui/core/TextField";
import {FaSpinner} from "react-icons/all";
import {useFormik} from "formik";
import AddNewAssessmentCategorySchema from "../../validations/AddNewAssessmentCategorySchema";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const AssessmentCategory = (props) => {
    const initialCategory = {
        'player_position_id': '',
        'category_name': '',
        'category_info': '',
    }

    const classes = useStyles();
    const [assessmentCategories, setAssessmentCategories] = useState([]);
    const [playerPositions, setPlayerPosition] = useState([]);
    const [loader, setLoader] = useState(false);
    const [openNewCategoryPopup, setOpenNewCategoryPopup] = useState(false);
    const [page, setPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [status, setStatus] = useState({});
    const [newCategory, setNewCategory] = useState(initialCategory);

    const getAssessmentCategory = useCallback(async () => {
        setLoader(true);
        const response = await getRequest(props, GET_ASSESSMENT_CATEGORIES, "page=" + page)
        setLoader(false);
        setTotalRows(response.data.total_rows)
        setAssessmentCategories(response.data.assessment_data)
    }, [page, props]);

    useEffect(() => {
        getAssessmentCategory().catch(() => {});
    }, [getAssessmentCategory]);



    const handleChange = (event) => {
        setStatus({...status, [event.target.name]: event.target.value.toString()});

        const requestParams = {
            'category_id': event.target.name,
            'status': event.target.value
        }
        postRequest(props, CHANGE_ASSESSMENT_CATEGORIES_STATUS, requestParams);
    }

    const columns = [
        {field: "id", headerName: "ID", width: 70},
        {
            field: "player_position",
            headerName: "Player Position",
            width: 250
        },
        {
            field: "player_position_short_name",
            headerName: "Player Position S/N",
            width: 230
        },
        {
            field: "assessment_category_name",
            headerName: "Category Name",
            width: 230
        },
        {
            field: "assessment_category_info",
            headerName: "Category Info",
            width: 230
        },
        {
            field: "status",
            headerName: "Status",
            width: 175,
            renderCell: (state) => {
                const current_status = (status[state.row.id]) ? status[state.row.id].toString() : state.row.status.toString()
                return (<FormControl className={classes.formControl}>
                    <InputLabel id={state.id.toString()}>Status</InputLabel>
                    <Select
                        labelId={state.id.toString()}
                        id={state.id.toString()}
                        name={state.id.toString()}
                        value={current_status}
                        onChange={handleChange}
                    >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={2}>In Active</MenuItem>
                    </Select>
                </FormControl>)
            },
        },
    ];

    const rows = [];

    if (assessmentCategories.length > 0) {
        assessmentCategories.map((assessmentCategory) => {
            rows.push({
                "id": assessmentCategory.id,
                "player_position": assessmentCategory.player_position.position_name,
                "player_position_short_name": assessmentCategory.player_position.short_name,
                "assessment_category_name": assessmentCategory.category_name,
                "assessment_category_info": (assessmentCategory.category_info) ? assessmentCategory.category_info : '-',
                "status": assessmentCategory.status
            });
            return assessmentCategory;
        });
    }

    const handleClose = () => {
        setOpenNewCategoryPopup(false);
    };

    const addNewCategory = async () => {
        setLoader(true);
        const player_positions = await getRequest(props, GET_PLAYER_POSITIONS);
        setPlayerPosition(player_positions.data)
        setLoader(false);
        setOpenNewCategoryPopup(true);
    }

    const inputHandler = (e) => {
        setNewCategory({...newCategory, [e.target.name]: e.target.value});
    }

    const submitHandler = (values) => {
        setLoader(true);

        const newCategoryData = {
            category_name: values.category_name,
            category_info: values.category_info,
            player_position_id: values.player_position_id,
        }

        postRequest(props, ADD_NEW_ASSESSMENT_CATEGORY, newCategoryData).then(() => {
            getAssessmentCategory().then(() => {
                setLoader(false);
                setOpenNewCategoryPopup(false);
                setNewCategory(initialCategory);
            });
        });
    }

    const formik = useFormik({
        initialValues: {
            'category_name': newCategory.category_name,
            'category_info': newCategory.category_info,
            'player_position_id': newCategory.player_position_id
        },
        validationSchema: AddNewAssessmentCategorySchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            submitHandler(values);
            inputHandler(values);
        }
    });

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Assessment Categories</h6>
                                    <div style={{textAlign: 'right'}}>
                                        <button className="btn btn-info" onClick={addNewCategory}>Add New Category
                                        </button>
                                    </div>
                                    <div className="table-responsive mt-2">

                                        <Dialog
                                            open={openNewCategoryPopup}
                                            onClose={handleClose}
                                            aria-labelledby="responsive-dialog-title"
                                        >
                                            <DialogTitle id="responsive-dialog-title">
                                                Add New Category
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="model_content">
                                                    <form onSubmit={formik.handleSubmit}>
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <InputLabel id="demo-simple-select-label"
                                                                            className="ml-4 mt-3"
                                                                            style={{
                                                                                overflow: "visible",
                                                                                textAlign: "left"
                                                                            }}>Player Position:</InputLabel>
                                                                <FormControl fullWidth className="mt-2">
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name="player_position_id"
                                                                        label="Player Position"
                                                                        variant="outlined"
                                                                        onChange={(e) => {
                                                                            formik.handleChange(e);
                                                                            inputHandler(e);
                                                                        }}
                                                                        // style={{minWidth: 100}}
                                                                        error={formik.touched.player_position_id && Boolean(formik.errors.player_position_id)}
                                                                        helperText={formik.touched.player_position_id && formik.errors.player_position_id}
                                                                    >
                                                                        {
                                                                            playerPositions.map((playerPosition) =>
                                                                                <MenuItem value={playerPosition.id}>
                                                                                    {playerPosition.position_name}
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-6 mt-5">
                                                                <TextField id="outlined-basic"
                                                                           className="mdc-text-field__input"
                                                                           name="category_name"
                                                                           label="Category Name"
                                                                           variant="outlined"
                                                                           InputLabelProps={{shrink: true}}
                                                                           onChange={(e) => {
                                                                               formik.handleChange(e);
                                                                               inputHandler(e);
                                                                           }}
                                                                           value={formik.values.category_name}
                                                                           error={formik.touched.category_name && Boolean(formik.errors.category_name)}
                                                                           helperText={formik.touched.category_name && formik.errors.category_name}
                                                                />
                                                            </div>
                                                            <div className="col-6 mt-5">
                                                                <TextField id="outlined-basic"
                                                                           className="mdc-text-field__input"
                                                                           name="category_info"
                                                                           label="Category Info"
                                                                           variant="outlined"
                                                                           InputLabelProps={{shrink: true}}
                                                                           onChange={(e) => {
                                                                               formik.handleChange(e);
                                                                               inputHandler(e);
                                                                           }}
                                                                           value={formik.values.category_info}
                                                                           error={formik.touched.category_info && Boolean(formik.errors.category_info)}
                                                                           helperText={formik.touched.category_info && formik.errors.category_info}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-md-12">
                                                                <Button type="submit"
                                                                        className="mdc-button mdc-button--raised w-100"
                                                                        variant="outlined" disabled={loader}>
                                                                    {loader && (
                                                                        <FaSpinner/>
                                                                    )}
                                                                    {loader && <span>&nbsp;Loading...</span>}
                                                                    {!loader && <span>Save Category</span>}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </DialogContentText>
                                            </DialogContent>
                                        </Dialog>

                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={15}
                                            autoHeight
                                            pagination
                                            rowCount={totalRows}
                                            rowsPerPageOptions={[15]}
                                            paginationMode="server"
                                            onPageChange={(newPage) => setPage(newPage)}
                                            loading={loader}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>
};

export default AssessmentCategory;

