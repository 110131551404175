import * as Yup from 'yup';

const EditProfileSchema = Yup.object().shape({
    first_name: Yup.string()
        .required('First name field can not be blank.!'),
    last_name: Yup.string()
        .required('Last name field can not be blank.!'),
    phone: Yup.string()
        .required('Last name field can not be blank.!')
});

export default EditProfileSchema;
