import * as Yup from 'yup';

const AddNewModuleSchema = Yup.object().shape({
    module_name: Yup.string()
        .required('Module name field can not be blank.!'),
    api_route: Yup.string()
        .required('API route field can not be blank.!')
});

export default AddNewModuleSchema;
