import React, {useCallback, useEffect, useState} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {DataGrid} from "@material-ui/data-grid";
import {getRequest, postRequest} from "../../utils/APICall";
import {CONVERT_TO_EVALUATOR, GET_ALL_SCOUT} from "../../Constants";
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const Scout = (props) => {
    const classes = useStyles();

    const [scout, setScout] = useState([]);
    const [loader, setLoader] = useState(false);

    const getScouts = useCallback(() => {
        setLoader(true);
         getRequest(props, GET_ALL_SCOUT).then((response) => {
             setScout(response.data);
             setLoader(false);
         });
    }, [props]);

    useEffect(() => {
        getScouts();
    }, [getScouts]);

    const convertToEvaluator = async (scout_id) => {
        console.log("scout_id================", scout_id);
        setLoader(true)
        const postParam = {
            scout_id: scout_id
        }
        postRequest(props, CONVERT_TO_EVALUATOR, postParam);

        setLoader(false)

        getScouts();
    }

    const columns = [
        {field: "id", headerName: "ID", width: 90},
        {
            field: "firstName",
            headerName: "First name",
            width: 130
        },
        {
            field: "lastName",
            headerName: "Last name",
            width: 130
        },
        {
            field: "email",
            headerName: "Email",
            width: 250
        },
        {
            field: "phone",
            headerName: "Phone",
            width: 130
        },
        {
            field: "dob",
            headerName: "DOB",
            width: 130
        },
        {
            field: "status",
            headerName: "Status",
            width: 130
        },
        {
            field: "-",
            headerName: "Convert To Evaluator",
            width: 200,
            renderCell: (state) => {
                return (
                    <FormControl className={classes.formControl}>
                        <button className="btn btn-info" style={{fontSize: "small"}}
                                onClick={() => convertToEvaluator(state.row.id)}>
                            Convert
                        </button>
                    </FormControl>
                )
            },
        }
    ];

    const rows = [];
    if (scout.length > 0) {
        scout.map((value) => {
            rows.push({
                "id": value.id,
                "firstName": value.first_name,
                "lastName": value.last_name,
                "email": value.email,
                "phone": (value.phone) ? value.phone : "-",
                "dob": (value.dob) ? value.dob : "-",
                "status": value.status,
                "-": value.type
            });
            return value;
        });
    }

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Scouts</h6>
                                    <div className="table-responsive">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            autoHeight
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>;
};
export default Scout;
