import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {checkLogin} from "../App";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            checkLogin() && restricted ?
                <Redirect to="/dashboard"/>
                : <Component {...props} />
        )}/>
    );
};

export default PublicRoute;
