import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {postRequest} from "../../utils/APICall";
import {RESET_PASSWORD_API} from "../../Constants";
import {toast} from "react-toastify";
import {FaSpinner} from "react-icons/all";
import {useFormik} from "formik";
import ResetPasswordSchema from "../../validations/ResetPasswordSchema";

const ResetPassword = (props) => {
    let history = useHistory();
    let defaultState = {
        otp: "",
        newPassword: "",
        confirmPassword: "",
        loading: false
    }

    let [resetPassword, setResetPassword] = useState(defaultState);

    const inputHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setResetPassword({...resetPassword, [name]: value});
    }

    const clearState = () => {
        setResetPassword(resetPassword = {...defaultState})
    }

    const submitHandler = async () => {
        setResetPassword({...resetPassword, "loading": true});

        if (resetPassword.newPassword === resetPassword.confirmPassword) {
            const formData = {
                otp: resetPassword.otp,
                password: resetPassword.newPassword,
            }
            await postRequest(props, RESET_PASSWORD_API, formData);
            setResetPassword(resetPassword = {"loading": false});
            setResetPassword(defaultState);
            history.push('/login')
        } else {
            clearState();
            toast.error("New password must match with confirm password.!");
        }
    }

    const formik = useFormik({
        initialValues: {
            otp: '',
            newPassword: '',
            confirmPassword: ""
        },
        validationSchema: ResetPasswordSchema,
        onSubmit: (values) => {
            console.log(values);
            submitHandler();
        }
    });

    return (
        <div className="body-wrapper">
            <div className="main-wrapper">
                <div className="page-wrapper full-page-wrapper d-flex align-items-center justify-content-center">
                    <main className="auth-page">
                        <div className="mdc-layout-grid">
                            <div className="mdc-layout-grid__inner">
                                <div
                                    className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                                <div
                                    className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet">
                                    <div className="mdc-card">
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="mdc-layout-grid">
                                                <div className="mdc-layout-grid__inner">
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                                        <TextField id="outlined-basic"
                                                                   className="mdc-text-field__input"
                                                                   label="OTP" type="text" name="otp" variant="outlined"
                                                                   onChange={(e) => {
                                                                       formik.handleChange(e);
                                                                       inputHandler(e);
                                                                   }}
                                                                   value={formik.values.otp}
                                                                   error={formik.touched.otp && Boolean(formik.errors.otp)}
                                                                   helperText={formik.touched.otp && formik.errors.otp}
                                                        />
                                                    </div>
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                                        <TextField id="outlined-basic"
                                                                   className="mdc-text-field__input"
                                                                   label="Password" type="password" name="newPassword"
                                                                   variant="outlined"
                                                                   onChange={(e) => {
                                                                       formik.handleChange(e);
                                                                       inputHandler(e);
                                                                   }}
                                                                   value={formik.values.newPassword}
                                                                   error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                                                   helperText={formik.touched.newPassword && formik.errors.newPassword}
                                                        />
                                                    </div>
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                                        <TextField id="outlined-basic"
                                                                   className="mdc-text-field__input"
                                                                   label="Confirm Password" type="password"
                                                                   name="confirmPassword" variant="outlined"
                                                                   onChange={(e) => {
                                                                       formik.handleChange(e);
                                                                       inputHandler(e);
                                                                   }}
                                                                   value={formik.values.confirmPassword}
                                                                   error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                                                   helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                                        />
                                                    </div>
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                                        <Button  type="submit" className="mdc-button mdc-button--raised w-100"
                                                                variant="outlined"
                                                                disabled={resetPassword.loading}>
                                                            {resetPassword.loading && (
                                                                <FaSpinner/>
                                                            )}
                                                            {resetPassword.loading && <span>&nbsp;Loading...</span>}
                                                            {!resetPassword.loading && <span>Reset
                                                            Password</span>}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div
                                    className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
}


export default ResetPassword;
