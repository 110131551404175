import * as Yup from 'yup';

const AddZohoPlanSchema = Yup.object().shape({
    plan_name: Yup.string()
        .required('Plan name field can not be blank.!'),
    plan_code: Yup.string()
        .required('Plan code field can not be blank.!'),
    plan_price: Yup.string()
        .required('Plan price field can not be blank.!'),
    plan_description: Yup.string()
        .required('Plan description field can not be blank.!'),
    interval: Yup.string()
        .required('Bill every field can not be blank.!')
});

export default AddZohoPlanSchema;
