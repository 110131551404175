import React, {useEffect, useState} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {DataGrid} from "@material-ui/data-grid";
import {getRequest} from "../../utils/APICall";
import {GET_ALL_PLAYERS_API} from "../../Constants";
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const User = (props) => {
    const classes = useStyles();
    const [users, setUser] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
         getRequest(props, GET_ALL_PLAYERS_API+"?sort_by=created&order_by=desc").then((response) => {
             setUser(response.data);
             setLoader(false);
         });
    }, [props]);

    const userType = (type_id) => {
        switch (type_id) {
            case 2:
                return 'PLAYER';
            case 3:
                return 'EVALUATOR';
            case 4:
                return 'TEAM';
            case 5:
                return 'ACADEMY';
            case 6:
                return 'FAN';
            case 7:
                return 'SCOUT';
            case 9:
                return 'PARENT';
            default:
                return 'USER'
        }
    }

    const columns = [
        {field: "id", headerName: "ID", width: 80},
        {
            field: "firstName",
            headerName: "First name",
            width: 145
        },
        {
            field: "lastName",
            headerName: "Last name",
            width: 145
        },
        {
            field: "email",
            headerName: "Email",
            width: 300
        },
        {
            field: "phone",
            headerName: "Phone",
            width: 120
        },
        {
            field: "dateJoined",
            headerName: "Date joined",
            width: 140
        },
        {
            field: "dob",
            headerName: "DOB",
            width: 120
        },
        {
            field: "purchases",
            headerName: "Purchases",
            width: 140,
        },
        {
            field: "type",
            headerName: "User Type",
            width: 130
        },
        {
            field: "status",
            headerName: "Status",
            width: 110
        }
    ];

    const rows = [];

    if (users.length > 0) {
        users.map((value) => {
            rows.push({
                "id": value.id,
                "firstName": value.first_name,
                "lastName": value.last_name,
                "email": value.email,
                "phone": (value.phone) ? value.phone : "-",
                "dateJoined": (value.created_at) ? moment(value.created_at).format("YYYY-MM-DD") : "-",
                "dob": (value.dob) ? value.dob : "-",
                "purchases": (value.n_purchases_count) ? value.n_purchases_count : 0,
                "type": userType(value.type),
                "status": value.status
            });
            return value;
        });
    }

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Users</h6>
                                    <div className="table-responsive">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            autoHeight
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>;
};
export default User;
