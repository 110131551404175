import React, {useEffect, useState} from 'react';
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import {getRequest} from "../../utils/APICall";
import {DASHBOARD_API} from "../../Constants";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const Layout = (props) => {
    const classes = useStyles();
    const [dashboard, setDashboard] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        getRequest(props, DASHBOARD_API).then((response) => {
            console.log("dashboard", response.data);
            setDashboard(response.data);
            setLoader(false);
        });
    }, [props]);

    return (
        <>
            <Backdrop className={classes.backdrop} open={loader}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Sidebar/>
            <div className="main-wrapper mdc-drawer-app-content">
                <div className="page-wrapper mdc-toolbar-fixed-adjust">
                    <Header/>
                    <main className="content-wrapper">
                        <div className="mdc-layout-grid">
                            <div className="mdc-layout-grid__inner">
                                <div
                                    className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet">
                                    <div className="mdc-card info-card info-card--success">
                                        <div className="card-inner mt-2">
                                            <h5 className="card-title">Total Active Evaluators</h5>
                                            <div className="card-icon-wrapper">
                                                <i className="">{dashboard.evaluator}</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet">
                                    <div className="mdc-card info-card info-card--danger">
                                        <div className="card-inner mt-2">
                                            <h5 className="card-title">Total Inactive Evaluators</h5>
                                            <div className="card-icon-wrapper">
                                                <i className="">{dashboard.inactive_evaluator}</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet">
                                    <div className="mdc-card info-card info-card--primary">
                                        <div className="card-inner mt-2">
                                            <h5 className="card-title">Total Players</h5>
                                            <div className="card-icon-wrapper">
                                                <i className="">{dashboard.player}</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet">
                                    <div className="mdc-card info-card info-card--info">
                                        <div className="card-inner mt-2">
                                            <h5 className="card-title">Total Teams</h5>
                                            <div className="card-icon-wrapper">
                                                <i className="">{dashboard.team}</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer/>
                </div>
            </div>
        </>
    );
}

export default Layout;
