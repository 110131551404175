import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {Button, Link} from "@material-ui/core";
import {checkLogin} from "../../App";
import {postRequest} from "../../utils/APICall";
import {LOGIN_API} from "../../Constants";
import {toast} from "react-toastify";
import {FaSpinner} from "react-icons/all";
import {useFormik} from 'formik';
import LoginSchema from "../../validations/LoginSchema";

const Login = (props) => {
    if (checkLogin()) {
        props.history.push('/');
    }

    const [login, setLogin] = useState({
        email: "",
        password: "",
        loading: false
    });

    const inputHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setLogin({...login, [name]: value});
    }

    const submitHandler = async () => {
        const loginData = {
            email: login.email,
            password: login.password,
            request_from: "admin"
        }

        setLogin({...login, "loading": true});

        const loginResponse = await postRequest(props, LOGIN_API, loginData);

        setLogin({...login, "loading": false});


        if (loginResponse.data.type === 'developer' || loginResponse.data.type === 'admin') {
                localStorage.setItem('user-info', JSON.stringify(loginResponse.data));
                props.history.push('/')
            } else {
                toast.error("You're not authorized user.!");
            }

    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            submitHandler();
        }
    });

    return <>
        <div className="body-wrapper">
            <div className="main-wrapper">
                <div className="page-wrapper full-page-wrapper d-flex align-items-center justify-content-center">
                    <main className="auth-page">
                        <div className="mdc-layout-grid">
                            <div className="mdc-layout-grid__inner">
                                <div
                                    className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                                <div
                                    className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet">
                                    <div className="mdc-card">
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="mdc-layout-grid">
                                                <div className="mdc-layout-grid__inner">
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                                        <TextField id="outlined-basic"
                                                                   className="mdc-text-field__input"
                                                                   name="email"
                                                                   label="username" variant="outlined"
                                                                   onChange={(e) => {
                                                                       formik.handleChange(e);
                                                                       inputHandler(e);
                                                                   }}
                                                                   value={formik.values.email}
                                                                   error={formik.touched.email && Boolean(formik.errors.email)}
                                                                   helperText={formik.touched.email && formik.errors.email}
                                                        />
                                                    </div>
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                                        <TextField type="password" id="outlined-basic"
                                                                   className="mdc-text-field__input"
                                                                   name="password"
                                                                   label="password" variant="outlined"
                                                                   onChange={(e) => {
                                                                       formik.handleChange(e);
                                                                       inputHandler(e);
                                                                   }}
                                                                   value={formik.values.password}
                                                                   error={formik.touched.password && Boolean(formik.errors.password)}
                                                                   helperText={formik.touched.password && formik.errors.password}
                                                        />
                                                    </div>
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-6-desktop d-flex align-items-start justify-content-start">
                                                        <Link href="/forgot-password">
                                                            Forgot password
                                                        </Link>
                                                    </div>
                                                    <div
                                                        className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                                        <Button type="submit"
                                                                className="mdc-button mdc-button--raised w-100"
                                                                variant="outlined" disabled={login.loading}>
                                                            {login.loading && (
                                                                <FaSpinner/>
                                                            )}
                                                            {login.loading && <span>&nbsp;Loading...</span>}
                                                            {!login.loading && <span>Login</span>}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div
                                    className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </>
}

export default Login;
