import React, {useCallback, useEffect, useState} from 'react';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import {makeStyles} from "@material-ui/core/styles";
import {getRequest} from "../../utils/APICall";
import {
    GET_ASSESSMENT_CATEGORY_BY_POSITION,
    GET_ASSESSMENT_SKILL_BY_CATEGORY,
    GET_ASSESSMENT_SKILLS_VALUES,
    GET_PLAYER_POSITIONS
} from "../../Constants";
import {DataGrid} from "@material-ui/data-grid";
import {useFormik} from "formik";
import FilterAssessmentSkillSchema from "../../validations/FilterAssessmentSkillSchema";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {Button, InputLabel} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {FaSpinner} from "react-icons/all";
import {NavLink} from "react-router-dom";
import Footer from "../Footer/Footer";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const AssessmentSkillValue = (props) => {
    const classes = useStyles();
    const initialSkillValueFilter = {
        'category_id': 0,
        'player_position_id': 0,
        'skill_id': 0
    }

    const [loader, setLoader] = useState(false);
    const [visibleFilter, setVisibleFilter] = useState(true);
    const [openFilterPopup, setOpenFilterPopup] = useState(false);
    const [assessmentValues, setAssessmentValues] = useState([]);
    const [page, setPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [filterSkillValue, setFilterSkillValue] = useState(initialSkillValueFilter);
    const [assessmentSkills, setAssessmentSkills] = useState([]);
    const [playerPositions, setPlayerPosition] = useState([]);
    const [assessmentCategories, setAssessmentCategories] = useState([]);

    const getAssessmentValues = useCallback(async (playerPositionId = 0, categoryId = 0, skillId = 0) => {
        setLoader(true);
        let queryString = "page=" + page;

        queryString += (playerPositionId > 0) ? '&player_position_id=' + playerPositionId : ''
        queryString += (categoryId > 0) ? '&category_id=' + categoryId : ''
        queryString += (skillId > 0) ? '&skill_id=' + skillId : ''

        const assessmentValueResponse = await getRequest(props, GET_ASSESSMENT_SKILLS_VALUES, queryString);
        setTotalRows(assessmentValueResponse.data.total_rows)
        setAssessmentValues(assessmentValueResponse.data.assessment_skill_value_data);
        setLoader(false);
    }, [page, props]);

    useEffect(() => {
        getAssessmentValues().catch(() => {});
    }, [getAssessmentValues]);

    const rows = [];

    if (assessmentValues.length > 0) {
        assessmentValues.map((assessmentValue) => {
            rows.push({
                "id": assessmentValue.id,
                "player_position": assessmentValue.skill.assessment_category.player_position.position_name,
                "assessment_category_name": assessmentValue.skill.assessment_category.category_name,
                "skill_name": assessmentValue.skill.skill_name,
                "rating": assessmentValue.rating,
                "statements": (assessmentValue.assessment_statements) ? assessmentValue.assessment_statements : []
            });
            return assessmentValue;
        });
    }

    const setStorage = (value_id) => {
        localStorage.setItem('value_id', value_id);
    }

    const columns = [
        {field: "id", headerName: "ID", width: 70},
        {
            field: "player_position",
            headerName: "Player Position",
            width: 250
        },
        {
            field: "assessment_category_name",
            headerName: "Assessment Category Name",
            width: 240
        },
        {
            field: "skill_name",
            headerName: "Skill Name",
            width: 240
        },
        {
            field: "rating",
            headerName: "Rating",
            width: 240
        },
        {
            field: "statements",
            headerName: "Statements",
            width: 240,
            renderCell: (state) => {
                if (state.row.statements.length > 0) {
                    return (<NavLink exact className="mdc-drawer-link" onClick={() => setStorage(state.row.id)} to={
                        {
                            pathname: "/view-assessment-statement"
                        }
                    }>
                    <span className="material-icons">
                        settings
                    </span>
                        View Statement
                    </NavLink>)
                } else {
                    // return (<FormControl className={classes.formControl}>
                    //     <button className="btn btn-info" style={{fontSize: "small"}}
                    //             onClick={() => showStatements()}>
                    //         Add New Statement
                    //     </button>
                    // </FormControl>)
                }
            },
        }
    ];

    const filterFormik = useFormik({
        initialValues: {
            'category_id': filterSkillValue.category_id,
            'player_position_id': filterSkillValue.player_position_id,
            'skill_id': filterSkillValue.skill_id
        },
        validationSchema: FilterAssessmentSkillSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            filterSkillHandler(values);
            filterInputHandler(values);
        }
    });

    const filterSkillHandler = async (values) => {
        await getAssessmentValues(values.player_position_id, values.category_id, values.skill_id);
        setOpenFilterPopup(false);
        setVisibleFilter(false);
    }

    const filterInputHandler = async (e) => {
        if (e.target) {
            if (e.target.name === 'player_position_id') {
                const assessmentSkillResponse = await getRequest(props, GET_ASSESSMENT_CATEGORY_BY_POSITION, 'player_position_id=' + e.target.value)
                setAssessmentCategories(assessmentSkillResponse.data);
            }
            if (e.target.name === 'category_id') {
                const assessmentSkillResponse = await getRequest(props, GET_ASSESSMENT_SKILL_BY_CATEGORY, 'category_id=' + e.target.value)
                setAssessmentSkills(assessmentSkillResponse.data);
            }
            setFilterSkillValue({...filterSkillValue, [e.target.name]: e.target.value});
        }
    }

    const handleClose = () => {
        // setOpenNewSkillPopup(false);
        setOpenFilterPopup(false);
    };

    const filter = async () => {
        if (filterSkillValue.player_position_id === 0) {
            await getPlayerPositions();
        }
        setOpenFilterPopup(true)
    }

    const resetFilter = async () => {
        await getAssessmentValues();
        setVisibleFilter(true);
    }

    const getPlayerPositions = async () => {
        setLoader(true);
        const player_positions = await getRequest(props, GET_PLAYER_POSITIONS);
        setPlayerPosition(player_positions.data)
        setLoader(false);
    }

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Assessment Skill Values</h6>
                                    <div className="d-flex flex-row bd-highlight mb-3 justify-content-end">
                                        <div className="p-2 bd-highlight">
                                            {
                                                (!visibleFilter)
                                                    ?
                                                    <button className="btn btn-secondary" style={{maxWidth: 50}}
                                                            onClick={resetFilter}>
                                                <span className="material-icons" style={{margin: "10%"}}>
                                                    refresh
                                                </span>
                                                    </button>
                                                    :
                                                    <button className="btn btn-secondary" style={{maxWidth: 50}}
                                                            onClick={filter}>
                                                        <span className="material-icons" style={{margin: "10%"}}>
                                                            search
                                                        </span>
                                                    </button>
                                            }
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <button className="btn btn-info"
                                                // onClick={addNewSkill}
                                            >
                                                <span className="material-icons" style={{margin: "10%"}}>
                                                    add
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="table-responsive mt-2">
                                        <Dialog
                                            open={openFilterPopup}
                                            onClose={handleClose}
                                            aria-labelledby="responsive-dialog-title"
                                        >
                                            <DialogTitle id="responsive-dialog-title">
                                                Filter
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="model_content">
                                                    <form onSubmit={filterFormik.handleSubmit}>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <InputLabel id="demo-simple-select-label"
                                                                            className="ml-4 mt-3"
                                                                            style={{
                                                                                overflow: "visible",
                                                                                textAlign: "left"
                                                                            }}>Player Position:</InputLabel>
                                                                <FormControl fullWidth className="mt-2">
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name="player_position_id"
                                                                        label="Player Position"
                                                                        variant="outlined"
                                                                        onChange={(e) => {
                                                                            filterFormik.handleChange(e);
                                                                            filterInputHandler(e);
                                                                        }}
                                                                        // style={{minWidth: 100}}
                                                                        error={filterFormik.touched.player_position_id && Boolean(filterFormik.errors.player_position_id)}
                                                                        helperText={filterFormik.touched.player_position_id && filterFormik.errors.player_position_id}
                                                                    >
                                                                        {
                                                                            playerPositions.map((playerPosition) =>
                                                                                <MenuItem value={playerPosition.id}>
                                                                                    {playerPosition.position_name}
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-12">
                                                                <InputLabel id="demo-simple-select-label"
                                                                            className="ml-4 mt-3"
                                                                            style={{
                                                                                overflow: "visible",
                                                                                textAlign: "left"
                                                                            }}>Assessment Category:</InputLabel>
                                                                <FormControl fullWidth className="mt-2">
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name="category_id"
                                                                        label="Assessment Category"
                                                                        variant="outlined"
                                                                        onChange={(e) => {
                                                                            filterFormik.handleChange(e);
                                                                            filterInputHandler(e);
                                                                        }}
                                                                        // style={{minWidth: 100}}
                                                                        error={filterFormik.touched.category_id && Boolean(filterFormik.errors.category_id)}
                                                                        helperText={filterFormik.touched.category_id && filterFormik.errors.category_id}
                                                                    >
                                                                        {
                                                                            assessmentCategories.map((assessmentCategory) =>
                                                                                <MenuItem value={assessmentCategory.id}>
                                                                                    {assessmentCategory.category_name}
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-12">
                                                                <InputLabel id="demo-simple-select-label"
                                                                            className="ml-4 mt-3"
                                                                            style={{
                                                                                overflow: "visible",
                                                                                textAlign: "left"
                                                                            }}>Assessment Skill:</InputLabel>
                                                                <FormControl fullWidth className="mt-2">
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name="skill_id"
                                                                        label="Assessment Skill"
                                                                        variant="outlined"
                                                                        onChange={(e) => {
                                                                            filterFormik.handleChange(e);
                                                                            filterInputHandler(e);
                                                                        }}
                                                                        // style={{minWidth: 100}}
                                                                        error={filterFormik.touched.skill_id && Boolean(filterFormik.errors.skill_id)}
                                                                        helperText={filterFormik.touched.skill_id && filterFormik.errors.skill_id}
                                                                    >
                                                                        {
                                                                            assessmentSkills.map((assessmentSkill) =>
                                                                                <MenuItem value={assessmentSkill.id}>
                                                                                    {assessmentSkill.skill_name}
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-md-12">
                                                                <Button type="submit"
                                                                        className="mdc-button mdc-button--raised w-100"
                                                                        variant="outlined" disabled={loader}>
                                                                    {loader && (
                                                                        <FaSpinner/>
                                                                    )}
                                                                    {loader && <span>&nbsp;Loading...</span>}
                                                                    {!loader && <span>Apply Filter</span>}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </DialogContentText>
                                            </DialogContent>
                                        </Dialog>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={15}
                                            autoHeight
                                            pagination
                                            rowCount={totalRows}
                                            rowsPerPageOptions={[15]}
                                            paginationMode="server"
                                            onPageChange={(newPage) => setPage(newPage)}
                                            loading={loader}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>
}

export default AssessmentSkillValue
