import * as Yup from 'yup';

const AddNewAssessmentCategorySchema = Yup.object().shape({
    category_name: Yup.string()
        .required('Category name field can not be blank.!'),
    player_position_id: Yup.string()
        .required('You must select a player position.!')
});

export default AddNewAssessmentCategorySchema;
