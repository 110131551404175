import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email("You've entered invalid email address")
        .required('Email field can not be blank.!'),
    password: Yup.string()
        .required('Password field can not be blank.!')
});

export default LoginSchema;
