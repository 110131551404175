import * as Yup from 'yup';

const AddNewPositionSchema = Yup.object().shape({
    position_name: Yup.string()
        .required('Position name field can not be blank.!'),
    short_name: Yup.string()
        .required('Short name field can not be blank.!')
});

export default AddNewPositionSchema;
