import React, {useEffect, useState} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import {Alert, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel} from "@mui/material";
import {getRequest, putRequest} from "../../utils/APICall";
import {GET_ALL_EVALUATORS_API, GET_SCOUT_REQUEST, STRIPE_DASHBORD_URL, UPDATE_SCOUT_REQUEST} from "../../Constants";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {DataGrid} from "@material-ui/data-grid";
import {FaSpinner} from "react-icons/all";
import moment from "moment";

const Evaluation = (props) => {
    const [editEvaluatorPopup, setEditEvaluatorPopup] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(false);
    const [evaluators, setEvaluators] = useState([]);
    const [currentEvaluator, setCurrentEvaluator] = useState(0);
    const [evaluator, setEvaluator] = useState(0);
    const [currentRow, setCurrentRow] = useState(0);

    const [sortModel, setSortModel] = useState([
        {
            field: 'created_at',
            sort: 'desc',
        },
    ]);

    useEffect(() => {
        setLoader(true);
        getRequest(props, GET_SCOUT_REQUEST+"?admin=true").then((response) => {
            setRows(response.data.map((value) => {
                let state = 'Pending';
                if (value.scout_request_status === 5 || value.scout_request_status === 6 || value.scout_report_id !== 0) {
                    state = 'Completed';
                }
                return {
                    "id": value.scout_request_id,
                    "created_at": moment(value.created_at).format("YYYY-MM-DD"),
                    "eva_created": value.report ? moment(value.report.created_at).format("YYYY-MM-DD") : '-',
                    "eva_completed": value.report && value.scout_request_status === 6? moment(value.report.updated_at).format("YYYY-MM-DD") : '-',
                    "reference": (moment(value.created_at).format("YYYYMMDD")+value.scout_request_id),
                    "player": value.player?.first_name + " " + value.player?.last_name,
                    "plan": value.playable?.title,
                    "status": state,
                    "evaluator": value.evaluator?.first_name + " " + value.evaluator?.last_name,
                    "evaluator_id": value.evaluator?.id,
                    "actions": null,
                    "payment_id": value.payment_id
                };
            }));
            setLoader(false);
        });
    }, [props]);

    useEffect(() => {
        setLoader(true);
        getRequest(props, GET_ALL_EVALUATORS_API).then((response) => {
            setEvaluators(response.data);
            setLoader(false);
        });
    }, [props]);

    const columns = [
        {field: "id", headerName: "ID", flex: 0.2, minWidth: 85},
        {
            field: "created_at",
            headerName: "Created at",
            flex: 1, minWidth: 140
        },
        {
            field: "eva_created",
            headerName: "Evaluator Created",
            flex: 1, minWidth: 185
        },
        {
            field: "eva_completed",
            headerName: "Evaluator Completed",
            flex: 1, minWidth: 205
        },
        {
            field: "reference",
            headerName: "Reference",
            flex: 1, minWidth: 140
        },
        {
            field: "player",
            headerName: "Player",
            flex: 1, minWidth: 160
        },
        {
            field: "plan",
            headerName: "Plan",
            flex: 1, minWidth: 320
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: 75,
            type: 'singleSelect',
            valueOptions: ['Pending', 'Completed'],
            valueFormatter: ({ id, value, field }) => {
                const option = ['Pending', 'Completed'].find(
                    ({ value: optionValue }) => optionValue === value
                );

                return option;
            },
        },
        {
            field: "evaluator",
            headerName: "Evaluator",
            flex: 1, minWidth: 75
        },
        {
            field: "evaluator_id",
            headerName: "Evaluator ID",
            width: 0,
            hide: true
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            renderCell: (params) => {
                return (
                    params.row.status !== 'Completed' ? <button type="button"
                                                                className="btn btn-info w-40"
                                                                style={{color: '#ffffff', backgroundColor: '#0641d9'}}
                                                                onClick={(e) => onEditClick(e, params)}>
                        Edit
                    </button> : ''
                );
            },
            flex: 0.6, minWidth: 100
        },
        {
            field: "payment",
            headerName: "Payment",
            sortable: false,
            renderCell: (params) => {
                return (
                    <a target="_blank" href={STRIPE_DASHBORD_URL + params.row.payment_id}>View</a>
                );
            },
            flex: 0.7, minWidth: 100
        }
    ];

    const editEvaluatorPopupHandler = () => {
        setEditEvaluatorPopup(true);
    }

    const handleClose = async () => {
        setEditEvaluatorPopup(false);
    };

    const handleChange = (event) => {
        setEvaluator(event.target.value);
        setShowAlert(false);
    };

    const onEditClick = (e, params) => {
        e.stopPropagation();
        setCurrentRow(params.row.id);
        setCurrentEvaluator(params.row.evaluator_id);
        setEvaluator(params.row.evaluator_id);
        editEvaluatorPopupHandler();
    }

    const updateRow = async () => {
        if (currentEvaluator === evaluator) {
            return handleClose();
        }
        if (evaluator === 0 || evaluator === undefined) {
            setShowAlert(true);
        } else {
            setRows((prevRows) => {
                const eva = evaluators.find(e => e.id === evaluator);
                return prevRows.map((row) =>
                    row.id === currentRow ? {
                        ...row,
                        evaluator: eva.first_name + " " + eva.last_name,
                        evaluator_id: eva.id
                    } : row
                );
            });
            await submitHandler();
            handleClose();
        }
    }

    const submitHandler = async () => {
        setLoader(true);
        const evaluatorData = {
            id: currentRow,
            evaluator_id: evaluator
        }
        const result = await putRequest(props, UPDATE_SCOUT_REQUEST, evaluatorData);

        setLoader(false);

        if (result.code !== 201) {
            return
        }

    }

    const handleSortChange = (model) => {
        /* if statement to prevent the infinite loop by confirming model is
         different than the current sortModel state */
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
        }
    };


    return <>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Evaluations</h6>
                                    <div className="table-responsive mt-2">
                                        <DataGrid
                                            sortModel={sortModel}
                                            onSortModelChange={(newSortModel) => handleSortChange(newSortModel)}
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            autoHeight
                                        />
                                    </div>
                                    <Dialog
                                        open={editEvaluatorPopup}
                                        onClose={handleClose}
                                        aria-labelledby="responsive-dialog-title"
                                    >
                                        <DialogTitle id="responsive-dialog-title">
                                            Edit evaluator
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="model_content">
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <FormControl fullWidth>
                                                            <InputLabel
                                                                id="demo-simple-select-label">Evaluator</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={evaluator || 0}
                                                                label="Evaluator"
                                                                onChange={handleChange}
                                                            >
                                                                {evaluators.map((data) => (
                                                                    <MenuItem key={data.id}
                                                                              value={data.id}>{data.first_name + " " + data.last_name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                {
                                                    showAlert === true &&
                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            <Alert variant="outlined" severity="error"
                                                                   onClose={() => setShowAlert(false)}>
                                                                Please select a evaluator
                                                            </Alert>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row mt-2">
                                                    <div className="col-md-6">
                                                        <button onClick={handleClose}
                                                                className="btn btn-danger w-100"
                                                                disabled={loader}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <button className="btn btn-info w-100"
                                                                style={{color: '#ffffff', backgroundColor: '#0641d9'}}
                                                                onClick={updateRow}
                                                                disabled={loader}>
                                                            {loader && (
                                                                <FaSpinner/>
                                                            )}
                                                            {loader && <span>&nbsp;Loading...</span>}
                                                            {!loader && <span>Save</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </DialogContentText>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </>
}

export default Evaluation;
