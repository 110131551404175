import axios from "axios";
import {apiTokenHeader} from "./common";
import {toast} from "react-toastify";

export const postRequest = async (props, URL, formData) => {
    const response = await axios.post(URL, formData, apiTokenHeader());

    if (response.data.code === 406) {
        toast.error(response.data.message)
        props.history.push('/');
    }
    if (response.data.status) {
        toast.success(response.data.message);
    } else {
        toast.error(response.data.message);
    }

    return response.data;
}

export const getRequest = async (props, URL, queryString) => {
    URL = (queryString) ? URL + "?" + queryString : URL;
    const response = await axios.get(URL, apiTokenHeader());

    if (response.data.code === 406) {
        toast.error(response.data.message)
        props.history.push('/');
    }

    return response.data;
}

export const putRequest = async (props, URL, formData) => {
    formData._method = "PUT";
    const response = await axios.post(URL, formData, apiTokenHeader());

    if (response.data.code === 406) {
        toast.error(response.data.message)
        props.history.push('/');
    }

    if (response.data.status) {
        toast.success(response.data.message);
    } else {
        toast.error(response.data.message);
    }

    return response.data;
}

export const deleteRequest = async (props, URL, formData) => {
    formData._method = "DELETE";
    const response = await axios.post(URL, formData, apiTokenHeader());

    if (response.data.code === 406) {
        toast.error(response.data.message)
        props.history.push('/');
    }

    if (response.data.status) {
        toast.success(response.data.message);
    } else {
        toast.error(response.data.message);
    }

    return response.data;
}



