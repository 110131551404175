import React from 'react';
import {Switch, withRouter} from "react-router-dom";
import Layout from "../Layout/Layout";
import Login from "../Login/Login";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ResetPassword from "../ResetPassword/ResetPassword";
import Error from "../Error/Error";
import Logout from "../Logout/Logout";
import EditProfile from "../EditProfile/EditProfile";
import User from "../User/User";
import Evaluator from "../Evaluator/Evaluator";
import Team from "../Team/Team";
import Module from "../Module/Module";
import UserTypes from "../UserTypes/UserTypes";
import ChangePermission from "../UserTypes/ChangePermission";
import Academy from "../Academy/Academy";
import ChangePassword from "../ChangePassword/ChangePassword";
import APILog from "../APILog/APILog";
import Position from "../Position/Position";
import Scout from "../Scout/Scout";
import ZohoPlan from "../ZohoPlan/ZohoPlan";
import PrivateRoute from "../../helper/PrivateRoute";
import PublicRoute from "../../helper/PublicRoute";
import AssessmentCategory from "../AssessmentCategory/AssessmentCategory";
import AssessmentSkill from "../AssessmentSkill/AssessmentSkill";
import AssessmentSkillValue from "../AssessmentSkillValue/AssessmentSkillValue";
import AssessmentSkillValueStatement from "../AssessmentSkillValue/AssmentSkillValueStatement";
import AddEvaluator from "../AddEvaluator/AddEvaluator";
import Evaluation from "../Evaluation/Evaluation";

const Router = () => {
    return <>
        <Switch>
            <PublicRoute exact path="/login" component={withRouter(Login)}/>
            <PublicRoute exact path="/forgot-password" component={withRouter(ForgotPassword)}/>
            <PublicRoute exact path="/reset-password" component={withRouter(ResetPassword)}/>
            <PrivateRoute exact path="/" component={withRouter(Layout)}/>
            <PrivateRoute exact path="/evaluators" component={withRouter(Evaluator)}/>
            <PrivateRoute exact path="/create-evaluator" component={withRouter(AddEvaluator)}/>
            <PrivateRoute exact path="/evaluations" component={withRouter(Evaluation)}/>
            <PrivateRoute exact path="/scouts" component={withRouter(Scout)}/>
            <PrivateRoute exact path="/users" component={withRouter(User)}/>
            <PrivateRoute exact path="/teams" component={withRouter(Team)}/>
            <PrivateRoute exact path="/academies" component={withRouter(Academy)}/>
            <PrivateRoute exact path="/edit-profile" component={withRouter(EditProfile)}/>
            <PrivateRoute exact path="/change-password" component={withRouter(ChangePassword)}/>
            <PrivateRoute exact path="/modules" component={withRouter(Module)}/>
            <PrivateRoute exact path="/user-types" component={withRouter(UserTypes)}/>
            <PrivateRoute exact path="/change-permission" component={withRouter(ChangePermission)}/>
            <PrivateRoute exact path="/logout" component={withRouter(Logout)}/>
            <PrivateRoute exact path="/api-logs" component={withRouter(APILog)}/>
            <PrivateRoute exact path="/player-positions" component={withRouter(Position)}/>
            <PrivateRoute exact path="/zoho-plans" component={withRouter(ZohoPlan)}/>
            <PrivateRoute exact path="/assessment-categories" component={withRouter(AssessmentCategory)}/>
            <PrivateRoute exact path="/assessment-skills" component={withRouter(AssessmentSkill)}/>
            <PrivateRoute exact path="/assessment-skills-values" component={withRouter(AssessmentSkillValue)}/>
            <PrivateRoute exact path="/view-assessment-statement"
                          component={withRouter(AssessmentSkillValueStatement)}/>
            <PublicRoute component={withRouter(Error)}/>
        </Switch>
    </>
}

export default Router;
