import React from 'react';
import {VERSION} from "../../Constants";

const Footer = () => (
    <footer className="ml-3">
        <div className="row">
            <div className="col-md-6 col-sm-6 col-8 text-left">
                <span className="tx-14">Copyright © <a href="/" target="_blank">Puck Recruiter </a>2021</span>
            </div>
            <div className="col-md-6 col-sm-6 col-4 text-right">
                <span className="tx-14">Version {VERSION}</span>
            </div>
        </div>
    </footer>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
