import * as Yup from 'yup';

const ResetPasswordSchema = Yup.object().shape({
    otp: Yup.number()
        .required('OTP field can not be blank.!'),
    newPassword: Yup.string()
        .required('Password field can not be blank.!'),
    confirmPassword: Yup.string()
        .required('Password field can not be blank.!')
        .oneOf([Yup.ref('newPassword'), null], 'Password and confirm must be match')
});

export default ResetPasswordSchema;
