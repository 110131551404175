export const apiTokenHeader = () => {
    const userInfo = JSON.parse(localStorage.getItem('user-info'));

    if (userInfo) {
        return {
            headers: {
                'Authorization': userInfo.token
            }
        };
    }
    return {};
}

export const userInfo = () => {
    const userInfo = JSON.parse(localStorage.getItem('user-info'));

    if (userInfo) {
        return userInfo;
    }
    return {};
}
