import React from 'react';

const Error = () => (
    <div className="body-wrapper">
        <div className="main-wrapper">
            <div className="page-wrapper full-page-wrapper">
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card p-0">
                                    <section className="error-header">
                                        <h1 className="mdc-typography--display2 mb-0">404 !</h1>
                                    </section>
                                    <section className="error-info">
                                        <p>Page not found</p>
                                        <p className="mb-2">The requested URL was not found on this
                                            server.</p>
                                        <a href="/">Go back to Home</a>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
);

Error.propTypes = {};

Error.defaultProps = {};

export default Error;
