import React, {useEffect, useState} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {DataGrid} from "@material-ui/data-grid";
import {getRequest, postRequest} from "../../utils/APICall";
import {GET_ALL_TEAMS_API, TEAM_STATUS_CHANGE_API} from "../../Constants";
import {InputLabel} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const Team = (props) => {
    const classes = useStyles();

    const [teams, setTeam] = useState([]);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const initialModelState = {
        "title": '',
        "users": [],
        'type': ''
    };
    const [model, setModel] = useState(initialModelState);

    useEffect(() => {
        setLoader(true);
         getRequest(props, GET_ALL_TEAMS_API).then((response) => {
             setTeam(response.data);
             setLoader(false);
         });
    }, [props]);
    const [status, setStatus] = useState({});

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = async (event) => {
        setStatus({...status, [event.target.name]: event.target.value});

        const requestParams = {
            'team_id': event.target.name,
            'status': event.target.value
        }

        postRequest(props, TEAM_STATUS_CHANGE_API, requestParams);
    };

    const showManagers = async (managers) => {
        setModel({...model, ...initialModelState});
        let stateModel;
        if (!managers.length) {
            stateModel = {
                title: 'Oops.!',
                users: [],
                type: 'manager'
            }
        } else {
            stateModel = {
                title: 'Academy Managers',
                users: managers
            }
        }
        setModel({...model, ...stateModel});
        handleClickOpen(true);
    }

    const showCoaches = (coaches) => {
        setModel({...model, ...initialModelState});
        let stateModel;
        if (!coaches.length) {
            stateModel = {
                title: 'Oops.!',
                users: [],
                type: 'coach'
            }
        } else {
            stateModel = {
                title: 'Academy Coaches',
                users: coaches
            }
        }
        setModel({...model, ...stateModel});
        handleClickOpen(true);
    }

    const showPlayers = (players) => {
        setModel({...model, ...initialModelState});
        let stateModel;
        if (!players.length) {
            stateModel = {
                title: 'Oops.!',
                users: [],
                type: 'player'
            }
        } else {
            stateModel = {
                title: 'Academy Players',
                users: players
            }
        }
        setModel({...model, ...stateModel});
        handleClickOpen(true);
    }

    const columns = [
        {field: "id", headerName: "ID", width: 90},
        {
            field: "firstName",
            headerName: "Academy name",
            width: 150
        },
        {
            field: "email",
            headerName: "Email",
            width: 175
        },
        {
            field: "phone",
            headerName: "Phone",
            width: 150
        },
        {
            field: "dob",
            headerName: "Founded On",
            width: 150
        },
        {
            field: "team_managers",
            headerName: "Managers",
            width: 140,
            renderCell: (state) => {
                const managers = JSON.parse(state.row.team_managers);
                // console.log("managers ===========>  ", managers);
                return (<FormControl className={classes.formControl}>
                    <button className="btn btn-info" style={{fontSize: "small"}} onClick={() => showManagers(managers)}>
                        View Managers
                    </button>
                </FormControl>)
            },
        },
        {
            field: "coaches",
            headerName: "Coaches",
            width: 140,
            renderCell: (state) => {
                const coaches = JSON.parse(state.row.coaches);

                return (<FormControl className={classes.formControl}>
                    <button className="btn btn-info" style={{fontSize: "small"}} onClick={() => showCoaches(coaches)}>
                        View Coaches
                    </button>
                </FormControl>)
            },
        },
        {
            field: "team_players",
            headerName: "Players",
            width: 140,
            renderCell: (state) => {
                const players = JSON.parse(state.row.team_players);
                return (<FormControl className={classes.formControl}>
                    <button className="btn btn-info" style={{fontSize: "small"}} onClick={() => showPlayers(players)}>
                        View Players
                    </button>
                </FormControl>)
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 175,
            renderCell: (state) => {
                return (<FormControl className={classes.formControl}>
                    <InputLabel id={state.id.toString()}>Status</InputLabel>
                    <Select
                        labelId={state.id.toString()}
                        id={state.id.toString()}
                        name={state.id.toString()}
                        value={(status[state.row.id]) ? status[state.row.id] : state.row.status}
                        onChange={handleChange}
                    >
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"Blocked"}>Blocked</MenuItem>
                    </Select>
                </FormControl>)
            },
        },
    ];

    const rows = [];
    if (teams.length > 0) {
        teams.map((value) => {
            rows.push({
                "id": value.id,
                "firstName": value.first_name + " " + value.last_name,
                "email": value.email,
                "phone": (value.phone) ? value.phone : "-",
                "dob": (value.dob) ? value.dob : "-",
                "team_managers": (value.team_managers) ? value.team_managers : "0",
                "coaches": (value.coaches) ? value.coaches : "0",
                "team_players": (value.team_players) ? value.team_players : "0",
                "status": value.status
            });
            return value;
        });
    }

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <DialogTitle id="responsive-dialog-title">
                                        {model.title}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="model_content">
                                            {(!model.users.length) ? `Currently, there is no ${model.type} added to this team.` : model.users.map((user) => (
                                                <div className="row mt-2">
                                                    <div className="col-2">
                                                        <img src={`data:image/png;base64,${user.profile_picture}`}
                                                             alt="" width={50} height={50}/>
                                                    </div>
                                                    <div className="col-3">
                                                        {user.first_name + " " + user.last_name}
                                                    </div>
                                                    <div className="col-7">
                                                        {user.email}
                                                    </div>
                                                </div>
                                            ))}
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Teams</h6>
                                    <div className="table-responsive">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            autoHeight
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>;
};
export default Team;
