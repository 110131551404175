export const API_BASE_URL =  "https://puck.allcode.com/api";
export const BUILD_FOLDER = process.env.REACT_APP_BUILD_FOLDER;
export const REACT_APP_PUBLIC_PATH = "/";

export const VERSION = process.env.REACT_APP_VERSION;
export const LOGIN_API = API_BASE_URL + "/login";
export const FORGOT_PASSWORD_API = API_BASE_URL + "/forgot-password";
export const RESET_PASSWORD_API = API_BASE_URL + "/reset-password";

export const GET_ALL_EVALUATORS_API = API_BASE_URL + "/get-all-evaluators";
export const CREATE_EVALUATOR = API_BASE_URL + "/register-evaluator";
export const EVALUATORS_STATUS_CHANGE_API = API_BASE_URL + "/evaluator-status-change";

export const GET_ALL_PLAYERS_API = API_BASE_URL + "/get-all-players";

export const GET_PROFILE = API_BASE_URL + "/get-profile";
export const EDIT_PROFILE_API = API_BASE_URL + "/edit-profile";

export const DASHBOARD_API = API_BASE_URL + "/dashboard";

export const GET_ALL_TEAMS_API = API_BASE_URL + "/get-all-teams";
export const TEAM_STATUS_CHANGE_API = API_BASE_URL + "/team-status-change";

export const GET_ALL_ACADEMIES_API = API_BASE_URL + "/get-all-academies";

export const GET_ALL_MODULES = API_BASE_URL + "/modules";
export const GET_ALL_USER_TYPES = API_BASE_URL + "/user-types";
export const UPDATE_PERMISSION = API_BASE_URL + "/update-permission";

export const CHANGE_PASSWORD = API_BASE_URL + "/change-password";

export const ADD_NEW_MODULE = API_BASE_URL + "/add-module";

export const GET_ALL_API_LOGS = API_BASE_URL + "/api-logs";
export const DELETE_API_LOGS = API_BASE_URL + "/delete-api-logs";

export const GET_PLAYER_POSITIONS = API_BASE_URL + "/positions";
export const ADD_PLAYER_POSITIONS = API_BASE_URL + "/add-position";

export const GET_ALL_SCOUT = API_BASE_URL + "/get-all-scouts";
export const CONVERT_TO_EVALUATOR = API_BASE_URL + "/convert-to-evaluator";

export const ADD_ZOHO_PLAN = API_BASE_URL + "/add-new-plan";
export const ZOHO_PLANS = API_BASE_URL + "/zoho-plans";

export const GET_ASSESSMENT_CATEGORIES = API_BASE_URL + "/assessment-categories";
export const CHANGE_ASSESSMENT_CATEGORIES_STATUS = API_BASE_URL + "/assessment-categories-status-change";
export const ADD_NEW_ASSESSMENT_CATEGORY = API_BASE_URL + "/add-assessment-category";

export const GET_ASSESSMENT_SKILLS = API_BASE_URL + "/assessment-skills";
export const ADD_ASSESSMENT_SKILL = API_BASE_URL + "/add-assessment-skill   ";
export const GET_ASSESSMENT_CATEGORY_BY_POSITION = API_BASE_URL + "/assessment-category";
export const GET_ASSESSMENT_SKILL_BY_CATEGORY = API_BASE_URL + "/filtered-assessment-skill";

export const GET_ASSESSMENT_SKILLS_VALUES = API_BASE_URL + "/assessment-skill-values";
export const GET_ASSESSMENT_SKILLS_VALUES_STATEMENTS_BY_RATING = API_BASE_URL + "/assessment-skill-values-by-rating";

export const MANAGE_ASSESSMENT_STATEMENT = API_BASE_URL + "/manage-assessment-statement";

export const GET_SCOUT_REQUEST = API_BASE_URL + "/get-scout-requests";

export const UPDATE_SCOUT_REQUEST = API_BASE_URL + "/update-scout-request";

export const STRIPE_DASHBORD_URL = "https://dashboard.stripe.com/test/payments/"
