import * as Yup from 'yup';

const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .required('Old Password field can not be blank.!'),
    newPassword: Yup.string()
        .required('Password field can not be blank.!'),
    confirmPassword: Yup.string()
        .required('Password field can not be blank.!')
        .oneOf([Yup.ref('newPassword'), null], 'Password and confirm must be match')
});

export default ChangePasswordSchema;
