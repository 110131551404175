import React, {useEffect, useState} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {DataGrid} from "@material-ui/data-grid";
import {deleteRequest, getRequest} from "../../utils/APICall";
import {DELETE_API_LOGS, GET_ALL_API_LOGS} from "../../Constants";
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const APILog = (props) => {
    const classes = useStyles();
    const [APILogs, setAPILog] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getAPILogs()
    });

    const getAPILogs = () => {
        setLoader(true);
         getRequest(props, GET_ALL_API_LOGS).then((response) => {
             setAPILog(response.data);
             setLoader(false);
         });
    }

    const showRequest = (data) => {
        alert(JSON.stringify(data, null, 4))
    }

    const copyToClip = async (text) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const showResponse = (data) => {
        alert(JSON.stringify(data, null, 4))
    }

    const columns = [
        {field: "id", headerName: "ID", width: 70},
        {
            field: "route",
            headerName: "Route",
            width: 145
        },
        {
            field: "url",
            headerName: "URL",
            width: 250
        },
        {
            field: "header",
            headerName: "Header",
            width: 145
        },

        {
            field: "ip",
            headerName: "IP",
            width: 120
        },
        {
            field: "prettyRequest",
            headerName: "Pretty Request",
            width: 200,
            renderCell: (state) => {
                const request = state.row.prettyRequest;
                return (<FormControl className={classes.formControl}>
                    <div className="row">
                        <div className="col-4">
                            <button className="btn btn-info" style={{fontSize: "smaller"}}
                                    onClick={() => showRequest(request)}>
                                Show
                            </button>
                        </div>
                        <div className="col-8">
                            <button className="btn btn-info" style={{fontSize: "smaller"}}
                                    onClick={() => copyToClip(JSON.stringify(request))}>
                                Copy To Clipboard
                            </button>
                        </div>
                    </div>
                </FormControl>)
            },
        },
        {
            field: "prettyResponse",
            headerName: "Pretty Response",
            width: 200,
            renderCell: (state) => {
                const response = state.row.prettyResponse;

                return (<FormControl className={classes.formControl}>
                    <div className="row">
                        <div className="col-4">
                            <button className="btn btn-info" style={{fontSize: "smaller"}}
                                    onClick={() => showResponse(response)}>
                                Show
                            </button>
                        </div>
                        <div className="col-8">
                            <button className="btn btn-info" style={{fontSize: "smaller"}}
                                    onClick={() => copyToClip(JSON.stringify(response))}>
                                Copy To Clipboard
                            </button>
                        </div>
                    </div>
                </FormControl>)
            },
        },

    ];

    const rows = [];

    if (APILogs.length > 0) {
        APILogs.map((value) => {
            rows.push({
                "id": value.id,
                "route": (value.route) ? value.route : "-",
                "url": (value.url) ? value.url : "-",
                "header": (value.header) ? value.header : "-",
                "ip": (value.ip) ? value.ip : "-",
                "prettyRequest": (value.request) ? value.request : "-",
                "prettyResponse": (value.response) ? value.response : "-",
            });
            return value;
        });
    }

    const deleteAll = async () => {
        setLoader(true)
        deleteRequest(props, DELETE_API_LOGS, {});
        setLoader(false)

        setAPILog([]);
    }

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Logs</h6>
                                    <div style={{textAlign: 'right'}}>
                                        <button className="btn btn-danger" onClick={deleteAll}>Delete All Logs</button>
                                    </div>
                                    <div className="table-responsive mt-2">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            autoHeight
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>;
};
export default APILog;
