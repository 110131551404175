import React, {useState} from 'react';
import {userInfo} from "../../utils/common";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

const Header = () => {
    const [isActive, setActive] = useState(false);
    const isActiveSidebar = useSelector((state) => state.toggleClass)

    const dispatch = useDispatch();

    const addClass = () => {
        setActive(true);
    };

    const removeClass = () => {
        setActive(false);
    }

    const changeClass = () => {
        if (isActiveSidebar) {
            dispatch({type: 'REMOVE_CLASS'});
        } else {
            dispatch({type: 'ADD_CLASS'});
        }
    }

    const user = userInfo();

    return <>
        <header className="mdc-top-app-bar">
            <div className="mdc-top-app-bar__row">
                <div className="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
                    <button
                        className="material-icons mdc-top-app-bar__navigation-icon mdc-icon-button sidebar-toggler"
                        onClick={changeClass}>menu
                    </button>
                    <span className="mdc-top-app-bar__title">PUCK RECRUITER!</span>
                </div>
                <div
                    className="mdc-top-app-bar__section mdc-top-app-bar__section--align-end mdc-top-app-bar__section-right">
                    <div className="menu-button-container menu-profile d-none d-md-block" onMouseEnter={addClass}
                         onMouseLeave={removeClass}>
                        <button className="mdc-button mdc-menu-button">
                            <span className="d-flex align-items-center">
                              <span className="figure">
                                <img
                                    src={(user.profile_picture) ? user.profile_picture : "assets/images/faces/face1.jpg"}
                                    alt="user" className="user"/>
                              </span>
                              <span className="user-name">{user.first_name + " " + user.last_name}</span>
                            </span>
                        </button>
                        <div
                            className={isActive ? 'mdc-menu mdc-menu-surface mdc-menu-surface--open' : 'mdc-menu mdc-menu-surface'}
                            tabIndex="-1"
                            style={{transformOrigin: "center", right: -25, top: 36, maxHeight: 154}}>
                            <ul className="mdc-list" role="menu" aria-hidden="true"
                                aria-orientation="vertical">
                                <li className="mdc-list-item" role="menuitem">
                                    <div className="item-thumbnail item-thumbnail-icon-only">
                                        <i className="mdi mdi-account-edit-outline text-primary"></i>
                                    </div>
                                    <div
                                        className="item-content d-flex align-items-start flex-column justify-content-center">
                                        <Link to="/edit-profile"><h6 className="item-subject font-weight-normal">Edit
                                            profile</h6></Link>
                                    </div>
                                </li>
                                <li className="mdc-list-item" role="menuitem">
                                    <div className="item-thumbnail item-thumbnail-icon-only">
                                        <i className="mdi mdi-textbox-password text-primary"></i>
                                    </div>
                                    <div
                                        className="item-content d-flex align-items-start flex-column justify-content-center">
                                        <Link to="/change-password"><h6
                                            className="item-subject font-weight-normal">Change
                                            Password</h6></Link>
                                    </div>
                                </li>
                                <li className="mdc-list-item" role="menuitem">
                                    <div className="item-thumbnail item-thumbnail-icon-only">
                                        <i className="mdi mdi-settings-outline text-primary"></i>
                                    </div>
                                    <div
                                        className="item-content d-flex align-items-start flex-column justify-content-center">
                                        <h6 className="item-subject font-weight-normal"><Link to="/logout">Logout</Link>
                                        </h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
}

export default Header;

