import * as Yup from 'yup';

const FilterAssessmentSkillSchema = Yup.object().shape({
    player_position_id: Yup.string()
        .required('You must select a player position.!'),
    category_id: Yup.string()
        .required('You must select a assessment category.!')
});

export default FilterAssessmentSkillSchema;
