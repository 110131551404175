import React, {useState} from 'react';
import {userInfo} from "../../utils/common";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

const imageStyle = {
    maxWidth: "100%"
};

const Sidebar = () => {
    const user = userInfo();
    const isActiveSidebar = useSelector((state) => state.toggleClass)

    const localSubMenu = (localStorage.getItem('sub-menu-hide') === 'true');
    const localDeveloperSubMenu = (localStorage.getItem('sub-developer-menu-hide') === 'true');

    const [isActiveSubMenu, setIsActiveSubMenu] = useState(localSubMenu);
    const [isActiveDeveloperSubMenu, setIsActiveDeveloperSubMenu] = useState(localDeveloperSubMenu);

    const changeClass = () => {
        setIsActiveSubMenu(!isActiveSubMenu);

        setIsActiveDeveloperSubMenu(false);

        localStorage.setItem('sub-menu-hide', !isActiveSubMenu)
        localStorage.setItem('sub-developer-menu-hide', false)
    }

    const changeDeveloperClass = () => {
        setIsActiveSubMenu(false);
        setIsActiveDeveloperSubMenu(!isActiveDeveloperSubMenu);
        localStorage.setItem('sub-menu-hide', false)
        localStorage.setItem('sub-developer-menu-hide', !isActiveDeveloperSubMenu)
    }

    const hideSubMenu = () => {
        setIsActiveSubMenu(false);
        setIsActiveDeveloperSubMenu(false);
        localStorage.setItem('sub-menu-hide', false)
        localStorage.setItem('sub-developer-menu-hide', false)
    }

    return <aside className={`mdc-drawer mdc-drawer--dismissible ${isActiveSidebar ? 'mdc-drawer--open' : ''}`}>
        <div className="mdc-drawer__header">
            <NavLink exact className="brand-logo" to="">
                <img src="assets/images/login_logo.png" alt="logo" style={imageStyle}/>
            </NavLink>
            <div className="user-info" style={{color: "white", textAlign: "left"}}>
                <p className="name">{user.first_name + " " + user.last_name}</p>
                <p className="email">{user.email}</p>
            </div>
        </div>
        <div className="divider"/>
        <div className="mdc-drawer__content">
            <div className="mdc-list-group">
                <nav className="mdc-list mdc-drawer-menu">
                    <div className="mdc-list-item mdc-drawer-item">
                        <NavLink exact className="mdc-drawer-link" to="" onClick={hideSubMenu}>
                            <span className="material-icons mr-1">
                            home
                            </span>
                            Dashboard
                        </NavLink>
                    </div>
                    <div className="mdc-list-item mdc-drawer-item">
                        <a className="mdc-expansion-panel-link" href="javascript:void(0);"
                           data-toggle="expansionPanel"
                           data-target="ui-sub-menu1"
                           onClick={changeClass}
                        >
                            <i className="material-icons mdc-list-item__start-detail mdc-drawer-item-icon"
                               aria-hidden="true">dashboard</i>
                            Users
                            <i className="mdc-drawer-arrow material-icons">chevron_right</i>
                        </a>

                        <div className="mdc-expansion-panel"
                             id="ui-sub-menu1"
                             style={isActiveSubMenu ? {display: "block"} : {}}
                        >
                            <nav className="mdc-list mdc-drawer-submenu">
                                <div className="mdc-list-item mdc-drawer-item">
                                    <NavLink exact className="mdc-drawer-link" to="/evaluators">
                                            <span className="material-icons mr-1">
                                                perm_identity
                                            </span>
                                        Evaluators
                                    </NavLink>
                                </div>
                                <div className="mdc-list-item mdc-drawer-item">
                                    <NavLink exact className="mdc-drawer-link" to="/scouts">
                                        <span className="material-icons mr-1">
                                        perm_identity
                                        </span>
                                        Scouts
                                    </NavLink>
                                </div>
                                <div className="mdc-list-item mdc-drawer-item">
                                    <NavLink exact className="mdc-drawer-link" to="/users">
                                        <span className="material-icons mr-1">
                                            supervisor_account
                                        </span>
                                        Users
                                    </NavLink>
                                </div>
                                <div className="mdc-list-item mdc-drawer-item">
                                    <NavLink exact className="mdc-drawer-link" to="/teams">
                                        <span className="material-icons mr-1">
                                            supervisor_account
                                        </span>
                                        Teams
                                    </NavLink>
                                </div>
                                <div className="mdc-list-item mdc-drawer-item">
                                    <NavLink exact className="mdc-drawer-link" to="/academies">
                                        <span className="material-icons mr-1">
                                            supervisor_account
                                        </span>
                                        Academies
                                    </NavLink>
                                </div>
                            </nav>
                        </div>
                    </div>

                    <div className="mdc-list-item mdc-drawer-item">
                        <NavLink exact className="mdc-drawer-link" to="/zoho-plans" onClick={hideSubMenu}>
                            <span className="material-icons mr-1">
                                perm_identity
                                </span>
                            Zoho Plans
                        </NavLink>
                    </div>

                    <div className="mdc-list-item mdc-drawer-item">
                        <NavLink exact className="mdc-drawer-link" to="/evaluations" onClick={hideSubMenu}>
                            <span className="material-icons mr-1">
                                assignment
                                </span>
                            Evaluations
                        </NavLink>
                    </div>

                    <div className="mdc-list-item mdc-drawer-item">
                        <NavLink exact className="mdc-drawer-link" to="/player-positions" onClick={hideSubMenu}>
                            <span className="material-icons mr-1">
                                portrait
                            </span>
                            Player Positions
                        </NavLink>
                    </div>
                    {
                        user.type === 'developer'
                        &&
                        <div>
                            <div className="mdc-list-item mdc-drawer-item">
                                <a className="mdc-expansion-panel-link" href="javascript:void(0);"
                                   data-toggle="expansionPanel"
                                   data-target="ui-sub-menu"
                                   onClick={changeDeveloperClass}
                                >
                                    <i className="material-icons mdc-list-item__start-detail mdc-drawer-item-icon"
                                       aria-hidden="true">dashboard</i>
                                    Developer
                                    <i className="mdc-drawer-arrow material-icons">chevron_right</i>
                                </a>
                                <div className="mdc-expansion-panel"
                                     id="ui-sub-menu"
                                     style={(isActiveDeveloperSubMenu) ? {display: "block"} : {}}
                                >
                                    <nav className="mdc-list mdc-drawer-submenu">
                                        <div className="mdc-list-item mdc-drawer-item">
                                            <NavLink exact className="mdc-drawer-link" to="/assessment-categories"
                                                     style={{padding: 0}}>
                                                    <span className="material-icons mr-1">
                                                        assessment
                                                    </span>
                                                Assessment Categories
                                            </NavLink>
                                        </div>
                                        <div className="mdc-list-item mdc-drawer-item">
                                            <NavLink exact className="mdc-drawer-link" to="/assessment-skills"
                                                     style={{padding: 0}}>
                                                    <span className="material-icons mr-1">
                                                        assessment
                                                    </span>
                                                Assessment Skills
                                            </NavLink>
                                        </div>
                                        <div className="mdc-list-item mdc-drawer-item">
                                            <NavLink exact className="mdc-drawer-link" to="/assessment-skills-values"
                                                     style={{padding: 0}}>
                                                    <span className="material-icons mr-1">
                                                        assessment
                                                    </span>
                                                Assessment Values
                                            </NavLink>
                                        </div>
                                        <div className="mdc-list-item mdc-drawer-item">
                                            <NavLink exact className="mdc-drawer-link" to="/modules"
                                                     style={{padding: 0}}>
                                                <span className="material-icons mr-1">
                                                    view_module
                                                </span>
                                                Modules
                                            </NavLink>
                                        </div>
                                        <div className="mdc-list-item mdc-drawer-item">
                                            <NavLink exact className="mdc-drawer-link" to="/user-types"
                                                     style={{padding: 0}}>
                                                <span className="material-icons mr-1">
                                                    settings
                                                </span>
                                                User Types
                                            </NavLink>
                                        </div>
                                        <div className="mdc-list-item mdc-drawer-item">
                                            <NavLink exact className="mdc-drawer-link" to="/api-logs"
                                                     style={{padding: 0}}>
                                                <span className="material-icons mr-1">
                                                    view_module
                                                </span>
                                                API Logs
                                            </NavLink>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                        </div>
                    }

                </nav>
            </div>
        </div>
    </aside>
};

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
