import React, {useState} from 'react';
import {Button} from "@material-ui/core";
import Sidebar from "../Sidebar/Sidebar";
import TextField from "@material-ui/core/TextField";
import {postRequest} from "../../utils/APICall";
import {CREATE_EVALUATOR} from "../../Constants";
import {useFormik} from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import {FaSpinner} from "react-icons/all";
import {checkLogin} from "../../App";
import CreateEvaluatorSchema from "../../validations/CreateEvaluatorSchema";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const AddEvaluator = (props) => {
    if (!checkLogin()) {
        props.history.push('/');
    }
    const [evaluator, setEvaluator] = useState({});
    const [loading, setLoading] = useState(false);

    const inputHandler = (e) => {
        if (typeof e === 'object') {
            if (e.target.name === 'dob') {
                setEvaluator({...evaluator, dob: e.target.value});
            } else {
                setEvaluator({...evaluator, [e.target.name]: e.target.value});
            }
        } else {
            setEvaluator({...evaluator, phone: e});
        }
    }

    const submitHandler = async (values) => {
        setLoading(true);
        const evaluatorData = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            // phone: values.phone,
            // dob: values.dob
        }
        const result = await postRequest(props, CREATE_EVALUATOR, evaluatorData);
        setLoading(false);

        if(result.code !== 201){
            return
        }

        props.history.push('/evaluators');
    }

    const formik = useFormik({
        initialValues: {
            'first_name': evaluator.first_name,
            'last_name': evaluator.last_name,
            'email': evaluator.email,
            // 'phone': evaluator.phone,
            // 'dob': evaluator.dob
        },
        validationSchema: CreateEvaluatorSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            submitHandler(values);
        }
    });

    return <>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div
                                className="stretch-card mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"/>
                            <div
                                className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet w-100">
                                <div className="mdc-card">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h1>Create Evaluator</h1>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-6">
                                                <TextField id="outlined-basic"
                                                           className="mdc-text-field__input"
                                                           name="first_name"
                                                           label="First Name *"
                                                           variant="outlined"
                                                           InputLabelProps={{shrink: true}}
                                                           onChange={(e) => {
                                                               formik.handleChange(e);
                                                               inputHandler(e);
                                                           }}
                                                           value={formik.values.first_name}
                                                           error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                                           helperText={formik.touched.first_name && formik.errors.first_name}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField id="outlined-basic"
                                                           className="mdc-text-field__input"
                                                           name="last_name"
                                                           label="Last Name *"
                                                           variant="outlined"
                                                           InputLabelProps={{shrink: true}}
                                                           onChange={(e) => {
                                                               formik.handleChange(e);
                                                               inputHandler(e);
                                                           }}
                                                           value={formik.values.last_name}
                                                           error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                                           helperText={formik.touched.last_name && formik.errors.last_name}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <TextField id="outlined-basic"
                                                           className="mdc-text-field__input"
                                                           name="email"
                                                           label="Email *"
                                                           variant="outlined"
                                                           InputLabelProps={{shrink: true}}
                                                           disabled={false}
                                                           onChange={(e) => {
                                                               formik.handleChange(e);
                                                               inputHandler(e);
                                                           }}
                                                           value={formik.values.email}
                                                           error={formik.touched.email && Boolean(formik.errors.email)}
                                                           helperText={formik.touched.email && formik.errors.email}
                                                />
                                            </div>
                                        </div>

                                        {/*<div className="row mt-4">*/}
                                        {/*    <div className="col-md-6">*/}
                                        {/*        <label htmlFor="birthDate">Date of Birth</label>*/}
                                        {/*        <input id="startDate" name="dob" className="form-control" onChange={(e) => {*/}
                                        {/*            inputHandler(e);*/}
                                        {/*        }} type="date"/>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-md-6">*/}
                                        {/*        <MuiPhoneNumber*/}
                                        {/*            type="text"*/}
                                        {/*            name="phone"*/}
                                        {/*            label="Phone Number"*/}
                                        {/*            variant="outlined"*/}
                                        {/*            defaultCountry={"in"}*/}
                                        {/*            value={formik.values.phone}*/}
                                        {/*            onChange={(e) => {*/}
                                        {/*                formik.handleChange(e);*/}
                                        {/*                inputHandler(e);*/}
                                        {/*            }}*/}
                                        {/*            error={formik.touched.phone && Boolean(formik.errors.phone)}*/}
                                        {/*            helperText={formik.touched.phone && formik.errors.phone}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <Button type="submit"
                                                        className="mdc-button mdc-button--raised w-100"
                                                        variant="outlined" disabled={loading}>
                                                    {loading && (
                                                        <FaSpinner/>
                                                    )}
                                                    {loading && <span>&nbsp;Loading...</span>}
                                                    {!loading && <span>Save Evaluator</span>}
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div
                                className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>
}

export default AddEvaluator;
