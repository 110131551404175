import {ADD_CLASS, REMOVE_CLASS} from "../actions/types";

const initialState = true;

const toggleClassReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CLASS:
            return true;
        case REMOVE_CLASS:
            return false;
        default:
            return state;
    }
}

export default toggleClassReducer;
