import React, {useEffect, useState} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {DataGrid} from "@material-ui/data-grid";
import {getRequest, postRequest} from "../../utils/APICall";
import {EVALUATORS_STATUS_CHANGE_API, GET_ALL_EVALUATORS_API} from "../../Constants";
import {InputLabel} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const Evaluator = (props) => {
    const classes = useStyles();

    const [evaluator, setEvaluator] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
         getRequest(props, GET_ALL_EVALUATORS_API).then((response) => {
             setEvaluator(response.data);
             setLoader(false);
         });
    }, [props]);
    const [status, setStatus] = useState({});

    const handleChange = async (event) => {
        setStatus({...status, [event.target.name]: event.target.value});

        const requestParams = {
            'evaluator_id': event.target.name,
            'status': event.target.value
        }

        postRequest(props, EVALUATORS_STATUS_CHANGE_API, requestParams);
    };

    const columns = [
        {field: "id", headerName: "ID", width: 90},
        {
            field: "firstName",
            headerName: "First name",
            flex: 1.0,
            minWidth: 200,
        },
        {
            field: "lastName",
            headerName: "Last name",
            flex: 1.0,
            minWidth: 200,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1.0,
            minWidth: 200,
        },
        // {
        //     field: "phone",
        //     headerName: "Phone",
        //     width: 175
        // },
        // {
        //     field: "dob",
        //     headerName: "DOB",
        //     width: 175
        // },
        {
            field: "status",
            headerName: "Status",
            flex: 1.0,
            minWidth: 200,
            renderCell: (state) => {
                return (<FormControl className={classes.formControl}>
                    <InputLabel id={state.id.toString()}>Status</InputLabel>
                    <Select
                        labelId={state.id.toString()}
                        id={state.id.toString()}
                        name={state.id.toString()}
                        value={(status[state.row.id]) ? status[state.row.id] : state.row.status}
                        onChange={handleChange}
                    >
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"Blocked"}>Blocked</MenuItem>
                    </Select>
                </FormControl>)
            },
        },
    ];

    const rows = [];
    if (evaluator.length > 0) {
        evaluator.map((value) => {
            rows.push({
                "id": value.id,
                "firstName": value.first_name,
                "lastName": value.last_name,
                "email": value.email,
                //"phone": (value.phone) ? value.phone : "-",
                //"dob": (value.dob) ? value.dob : "-",
                "status": value.status
            });
            return value;
        });
    }

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card">
                                    <div style={{textAlign: 'right'}}>
                                        <button className="btn btn-success" onClick={() => props.history.push("create-evaluator")}>Add Evaluator</button>
                                    </div>
                                    <h6 className="card-title card-padding pb-0">Evaluators</h6>
                                    <div className="table-responsive">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            autoHeight
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>;
};
export default Evaluator;
