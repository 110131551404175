import React, {useEffect, useState} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import {FaSpinner} from "react-icons/all";
import {useFormik} from "formik";
import {getRequest, postRequest} from "../../utils/APICall";
import {ADD_ZOHO_PLAN, ZOHO_PLANS} from "../../Constants";
import AddZohoPlanSchema from "../../validations/AddZohoPlanSchema";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {DataGrid} from "@material-ui/data-grid";

const ZohoPlan = (props) => {
    const initialValues = {
        'plan_name': '',
        'plan_code': '',
        'plan_price': 0,
        'interval': 1,
        'interval_unit': 'months',
        'plan_description': '',
    }
    const [openNewPlanPopup, setOpeNewPlanPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [loader, setLoader] = useState(false);
    const [newPlan, setNewPlan] = useState(initialValues);

    const handleClose = async () => {
        setOpeNewPlanPopup(false);
        setNewPlan(initialValues)
    };

    useEffect(() => {
        setLoader(true);
        getRequest(props, ZOHO_PLANS, 'from_admin=1').then((response) => {
            setPlans(response.data);
            setLoader(false);
        });
    }, [props]);

    const submitHandler = async (values) => {
        console.log("loader", loader);
        setLoading(true);
        const newPlanData = {
            'plan_name': values.plan_name,
            'plan_code': values.plan_code.replace(/\s+/g, '-').toLowerCase(),
            'plan_price': values.plan_price,
            'interval': values.interval,
            'interval_unit': values.interval_unit,
            'plan_description': values.plan_description,
        }

        postRequest(props, ADD_ZOHO_PLAN, newPlanData);

        setLoading(false);
        setOpeNewPlanPopup(false);
        setNewPlan(initialValues);
    }

    const inputHandler = (e) => {
        setNewPlan({...newPlan, [e.target.name]: e.target.value});
    }

    const formik = useFormik({
        initialValues: {
            'plan_name': newPlan.plan_name,
            'plan_code': newPlan.plan_code,
            'plan_price': newPlan.plan_price,
            'interval': newPlan.interval,
            'interval_unit': newPlan.interval_unit,
            'plan_description': newPlan.plan_description
        },
        validationSchema: AddZohoPlanSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            submitHandler(values);
            inputHandler(values);
        }
    });

    const columns = [
        {field: "id", headerName: "ID", width: 90},
        {
            field: "plan_name",
            headerName: "Plan Name",
            width: 250
        },
        {
            field: "plan_code",
            headerName: "Plan Code",
            width: 250
        },
        {
            field: "product_id",
            headerName: "Product Id",
            width: 250
        },
        {
            field: "plan_price",
            headerName: "Plan Price",
            width: 300
        }
    ];

    const rows = [];
    if (plans.length > 0) {
        plans.map((value) => {
            rows.push({
                "id": value.id,
                "plan_name": value.plan_name,
                "plan_code": value.plan_code,
                "product_id": value.product_id,
                "plan_price": value.plan_price
            });
            return value;
        });
    }

    const newPlanPopupHandler = () => {
        setOpeNewPlanPopup(true);
    }

    return <>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Zoho Plans</h6>
                                    <div style={{textAlign: 'right'}}>
                                        <button className="btn btn-success" onClick={newPlanPopupHandler}>Add Zoho Plan
                                        </button>
                                        <Dialog
                                            open={openNewPlanPopup}
                                            onClose={handleClose}
                                            aria-labelledby="responsive-dialog-title"
                                        >
                                            <DialogTitle id="responsive-dialog-title">
                                                Add New Plan
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="model_content">
                                                    <form onSubmit={formik.handleSubmit}>
                                                        <div className="row mt-4">
                                                            <div className="col-md-6 col-xl-6 col-12 mt-2">
                                                                <TextField id="outlined-basic"
                                                                           className="mdc-text-field__input"
                                                                           name="plan_name"
                                                                           label="Plan Name"
                                                                           variant="standard"
                                                                           InputLabelProps={{shrink: true}}
                                                                           onChange={(e) => {
                                                                               formik.handleChange(e);
                                                                               inputHandler(e);
                                                                           }}
                                                                           value={formik.values.plan_name}
                                                                           error={formik.touched.plan_name && Boolean(formik.errors.plan_name)}
                                                                           helperText={formik.touched.plan_name && formik.errors.plan_name}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-xl-6 col-12 mt-2">
                                                                <TextField id="outlined-basic"
                                                                           className="mdc-text-field__input"
                                                                           name="plan_code"
                                                                           label="Plan Code"
                                                                           variant="standard"
                                                                           InputLabelProps={{shrink: true}}
                                                                           onChange={(e) => {
                                                                               formik.handleChange(e);
                                                                               inputHandler(e);
                                                                           }}
                                                                           value={formik.values.plan_code}
                                                                           error={formik.touched.plan_code && Boolean(formik.errors.plan_code)}
                                                                           helperText={formik.touched.plan_code && (formik.errors.plan_code) ? formik.errors.plan_code : 'A unique code of your choice which lets you identify this plan.'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-6 col-xl-6 mt-2"
                                                                 style={{"margin": "auto"}}>
                                                                <TextField id="outlined-basic"
                                                                           className="mdc-text-field__input"
                                                                           name="plan_price"
                                                                           label="Plan Price"
                                                                           variant="standard"
                                                                           InputLabelProps={{shrink: true}}
                                                                           onChange={(e) => {
                                                                               formik.handleChange(e);
                                                                               inputHandler(e);
                                                                           }}
                                                                           value={formik.values.plan_price}
                                                                           error={formik.touched.plan_price && Boolean(formik.errors.plan_price)}
                                                                           helperText={formik.touched.plan_price && formik.errors.plan_price}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-md-6 col-xl-6 mt-2"
                                                                 style={{"margin": "auto"}}>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <TextField id="outlined-basic"
                                                                                   className="mdc-text-field__input"
                                                                                   name="interval"
                                                                                   label="Bill Every"
                                                                                   variant="standard"
                                                                                   InputLabelProps={{shrink: true}}
                                                                                   onChange={(e) => {
                                                                                       formik.handleChange(e);
                                                                                       inputHandler(e);
                                                                                   }}
                                                                                   value={formik.values.interval}
                                                                                   error={formik.touched.interval && Boolean(formik.errors.interval)}
                                                                                   helperText={formik.touched.interval && formik.errors.interval}
                                                                        />
                                                                    </div>
                                                                    <div className="col-6 mt-3">
                                                                        <Select
                                                                            labelId=""
                                                                            id="interval_unit"
                                                                            name="interval_unit"
                                                                            value={formik.values.interval_unit}
                                                                            onChange={formik.handleChange}
                                                                        >
                                                                            <MenuItem value={"weeks"}>Weeks</MenuItem>
                                                                            <MenuItem value={"months"}>Months</MenuItem>
                                                                            <MenuItem value={"years"}>Years</MenuItem>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-12 mt-2">
                                                                <TextField id="outlined-basic"
                                                                           className="mdc-text-field__input"
                                                                           name="plan_description"
                                                                           label="Plan Description"
                                                                           variant="standard"
                                                                           InputLabelProps={{shrink: true}}
                                                                           onChange={(e) => {
                                                                               formik.handleChange(e);
                                                                               inputHandler(e);
                                                                           }}
                                                                    // rows={4}
                                                                           multiline={true}
                                                                           value={formik.values.plan_description}
                                                                           error={formik.touched.plan_description && Boolean(formik.errors.plan_description)}
                                                                           helperText={formik.touched.plan_description && formik.errors.plan_description}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-md-12">
                                                                <Button type="submit"
                                                                        className="mdc-button mdc-button--raised w-100"
                                                                        variant="outlined" disabled={loading}>
                                                                    {loading && (
                                                                        <FaSpinner/>
                                                                    )}
                                                                    {loading && <span>&nbsp;Loading...</span>}
                                                                    {!loading && <span>Save Plan</span>}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </DialogContentText>
                                            </DialogContent>
                                        </Dialog>
                                    </div>
                                    <div className="table-responsive mt-2">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            autoHeight
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </>
}

export default ZohoPlan;
