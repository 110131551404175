import React, {useEffect, useState} from 'react';
import {Button} from "@material-ui/core";
import Sidebar from "../Sidebar/Sidebar";
import TextField from "@material-ui/core/TextField";
import {getRequest, putRequest} from "../../utils/APICall";
import {EDIT_PROFILE_API, GET_PROFILE} from "../../Constants";
import {useFormik} from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import {FaSpinner} from "react-icons/all";
import EditProfileSchema from "../../validations/EditProfileSchema";
import {checkLogin} from "../../App";
import {userInfo} from "../../utils/common";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const EditProfile = (props) => {
    if (!checkLogin()) {
        props.history.push('/');
    }
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
         getRequest(props, GET_PROFILE).then((response) => {
             const data = response.data;
             setProfile(data);
         });
    }, [props]);

    const inputHandler = (e) => {
        if (typeof e === 'object') {
            if (e.target.name === 'dob') {
                setProfile({...profile, dob: e.target.value});
            } else {
                setProfile({...profile, [e.target.name]: e.target.value});
            }
        } else {
            setProfile({...profile, phone: e});
        }
    }

    const submitHandler = async (values) => {
        setLoading(true);
        const profileData = {
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone
        }
        putRequest(props, EDIT_PROFILE_API, profileData);
        setLoading(false);

        let user = userInfo();
        user.first_name = values.first_name;
        user.last_name = values.last_name;

        localStorage.setItem('user-info', JSON.stringify(user));
        props.history.push('/');
    }

    const formik = useFormik({
        initialValues: {
            'first_name': profile.first_name,
            'last_name': profile.last_name,
            'phone': profile.phone,
            'email': profile.email
        },
        validationSchema: EditProfileSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            submitHandler(values);
        }
    });

    return <>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div
                                className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                            <div
                                className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet w-100">
                                <div className="mdc-card">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h1>Edit Profile</h1>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-6">
                                                <TextField id="outlined-basic"
                                                           className="mdc-text-field__input"
                                                           name="first_name"
                                                           label="First Name"
                                                           variant="outlined"
                                                           InputLabelProps={{shrink: true}}
                                                           onChange={(e) => {
                                                               formik.handleChange(e);
                                                               inputHandler(e);
                                                           }}
                                                           value={formik.values.first_name}
                                                           error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                                           helperText={formik.touched.first_name && formik.errors.first_name}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField id="outlined-basic"
                                                           className="mdc-text-field__input"
                                                           name="last_name"
                                                           label="Last Name"
                                                           variant="outlined"
                                                           InputLabelProps={{shrink: true}}
                                                           onChange={(e) => {
                                                               formik.handleChange(e);
                                                               inputHandler(e);
                                                           }}
                                                           value={formik.values.last_name}
                                                           error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                                           helperText={formik.touched.last_name && formik.errors.last_name}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-md-6">
                                                <MuiPhoneNumber
                                                    type="text"
                                                    name="phone"
                                                    label="Phone Number"
                                                    variant="outlined"
                                                    defaultCountry={"in"}
                                                    value={formik.values.phone}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        inputHandler(e);
                                                    }}
                                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                                    helperText={formik.touched.phone && formik.errors.phone}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField id="outlined-basic"
                                                           className="mdc-text-field__input"
                                                           name="email"
                                                           label="Email"
                                                           variant="outlined"
                                                           InputLabelProps={{shrink: true}}
                                                           disabled={true}
                                                           onChange={(e) => {
                                                               formik.handleChange(e);
                                                               inputHandler(e);
                                                           }}
                                                           value={formik.values.email}
                                                           error={formik.touched.email && Boolean(formik.errors.email)}
                                                           helperText={formik.touched.email && formik.errors.email}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <Button type="submit"
                                                        className="mdc-button mdc-button--raised w-100"
                                                        variant="outlined" disabled={loading}>
                                                    {loading && (
                                                        <FaSpinner/>
                                                    )}
                                                    {loading && <span>&nbsp;Loading...</span>}
                                                    {!loading && <span>Save Profile</span>}
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div
                                className="stretch-card mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-1-tablet"/>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>
}

export default EditProfile;
