import React, {useCallback, useEffect, useState} from 'react';
import {getRequest, postRequest} from "../../utils/APICall";
import {
    ADD_ASSESSMENT_SKILL,
    CHANGE_ASSESSMENT_CATEGORIES_STATUS,
    GET_ASSESSMENT_CATEGORY_BY_POSITION,
    GET_ASSESSMENT_SKILLS,
    GET_PLAYER_POSITIONS
} from "../../Constants";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import {DataGrid} from "@material-ui/data-grid";
import Footer from "../Footer/Footer";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import {Button, InputLabel} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {FaSpinner} from "react-icons/all";
import {useFormik} from "formik";
import AddNewAssessmentSkillSchema from "../../validations/AddNewAssessmentSkillSchema";
import TextField from "@material-ui/core/TextField";
import FilterAssessmentSkillSchema from "../../validations/FilterAssessmentSkillSchema";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const AssessmentSkill = (props) => {
    const initialSkill = {
        'category_id': '',
        'player_position_id': '',
        'skill_name': '',
        'skill_info': '',
    }

    const initialSkillFilter = {
        'category_id': 0,
        'player_position_id': 0
    }

    const classes = useStyles();
    const [assessmentSkills, setAssessmentSkills] = useState([]);
    const [playerPositions, setPlayerPosition] = useState([]);
    const [assessmentCategories, setAssessmentCategories] = useState([]);
    const [loader, setLoader] = useState(false);
    const [openNewSkillPopup, setOpenNewSkillPopup] = useState(false);
    const [openFilterPopup, setOpenFilterPopup] = useState(false);
    const [page, setPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [status, setStatus] = useState({});
    const [newSkill, setNewSkill] = useState(initialSkill);
    const [filterSkill, setFilterSkill] = useState(initialSkillFilter);

    const getAssessmentSkill = useCallback(async (page, player_position_id = 0, category_id = 0) => {
        setLoader(true);
        const response = await getRequest(props, GET_ASSESSMENT_SKILLS, "page=" + page + "&player_position_id=" + player_position_id + "&category_id=" + category_id)
        setLoader(false);
        setTotalRows(response.data.total_rows)
        setAssessmentSkills(response.data.assessment_skill_data)
    }, [props]);

    useEffect(() => {
        getAssessmentSkill(page).catch(() => {})
    }, [page, getAssessmentSkill]);

    const handleChange = (event) => {
        setStatus({...status, [event.target.name]: event.target.value.toString()});

        const requestParams = {
            'category_id': event.target.name,
            'status': event.target.value
        }
        postRequest(props, CHANGE_ASSESSMENT_CATEGORIES_STATUS, requestParams);
    }

    const columns = [
        {field: "id", headerName: "ID", width: 70},
        {
            field: "player_position",
            headerName: "Player Position",
            width: 250
        },
        {
            field: "assessment_category_name",
            headerName: "Assessment Category Name",
            width: 240
        },
        {
            field: "skill_name",
            headerName: "Skill Name",
            width: 240
        },
        {
            field: "skill_info",
            headerName: "Skill Info",
            width: 240
        },
        {
            field: "status",
            headerName: "Status",
            width: 175,
            renderCell: (state) => {
                const current_status = (status[state.row.id]) ? status[state.row.id].toString() : state.row.status.toString()
                return (<FormControl className={classes.formControl}>
                    <InputLabel id={state.id.toString()}>Status</InputLabel>
                    <Select
                        labelId={state.id.toString()}
                        id={state.id.toString()}
                        name={state.id.toString()}
                        value={current_status}
                        onChange={handleChange}
                    >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={2}>In Active</MenuItem>
                    </Select>
                </FormControl>)
            },
        },
    ];

    const rows = [];

    if (assessmentSkills.length > 0) {
        assessmentSkills.map((assessmentSkill) => {
            rows.push({
                "id": assessmentSkill.id,
                "player_position": assessmentSkill.assessment_category.player_position.position_name,
                "assessment_category_name": assessmentSkill.assessment_category.category_name,
                "skill_name": assessmentSkill.skill_name,
                "skill_info": (assessmentSkill.skill_info) ? assessmentSkill.skill_info : '-',
                "status": assessmentSkill.status
            });
            return assessmentSkill;
        });
    }

    const handleClose = () => {
        setOpenNewSkillPopup(false);
        setOpenFilterPopup(false);
    };

    const addNewSkill = async () => {
        await getPlayerPositions();
        setOpenNewSkillPopup(true);
    }

    const filter = async () => {
        await getPlayerPositions();
        setOpenFilterPopup(true);
    }

    const getPlayerPositions = async () => {
        setLoader(true);
        const player_positions = await getRequest(props, GET_PLAYER_POSITIONS);
        setPlayerPosition(player_positions.data)
        setLoader(false);
    }

    const inputHandler = async (e) => {
        if (e.target) {
            if (e.target.name === 'player_position_id') {
                const assessmentSkillResponse = await getRequest(props, GET_ASSESSMENT_CATEGORY_BY_POSITION, 'player_position_id=' + e.target.value)
                setAssessmentCategories(assessmentSkillResponse.data);
            }
            setNewSkill({...newSkill, [e.target.name]: e.target.value});
        }
    }

    const filterInputHandler = async (e) => {
        if (e.target) {
            if (e.target.name === 'player_position_id') {
                const assessmentSkillResponse = await getRequest(props, GET_ASSESSMENT_CATEGORY_BY_POSITION, 'player_position_id=' + e.target.value)
                setAssessmentCategories(assessmentSkillResponse.data);
            }
            setFilterSkill({...filterSkill, [e.target.name]: e.target.value});
        }
    }

    const submitHandler = (values) => {
        setLoader(true);

        const newSkillData = {
            skill_name: values.skill_name,
            skill_info: values.skill_info,
            category_id: values.category_id
        }

        postRequest(props, ADD_ASSESSMENT_SKILL, newSkillData).then(() => {
            getAssessmentSkill(page).then(() => {
                setLoader(false);
                setOpenNewSkillPopup(false);
                setNewSkill(initialSkill);
            });
        });
    }

    const formik = useFormik({
        initialValues: {
            'category_id': newSkill.category_id,
            'player_position_id': newSkill.player_position_id,
            'skill_name': newSkill.skill_name,
            'skill_info': newSkill.skill_info
        },
        validationSchema: AddNewAssessmentSkillSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            submitHandler(values);
            inputHandler(values);
        }
    });

    const filterFormik = useFormik({
        initialValues: {
            'category_id': filterSkill.category_id,
            'player_position_id': filterSkill.player_position_id
        },
        validationSchema: FilterAssessmentSkillSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            filterSkillHandler(values);
            filterInputHandler(values);
        }
    });

    const filterSkillHandler = async (values) => {
        await getAssessmentSkill(values.player_position_id, values.category_id);
        setOpenFilterPopup(false);
    }

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <div className="mdc-card">
                                    <h6 className="card-title card-padding pb-0">Assessment Skills</h6>
                                    <div className="d-flex flex-row bd-highlight mb-3 justify-content-end">
                                        <div className="p-2 bd-highlight">
                                            <button className="btn btn-secondary" style={{maxWidth: 50}}
                                                    onClick={filter}>
                                                <span className="material-icons" style={{margin: "10%"}}>
                                                    search
                                                </span>
                                            </button>
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <button className="btn btn-info" onClick={addNewSkill}>
                                                <span className="material-icons" style={{margin: "10%"}}>
                                                    add
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="table-responsive mt-2">
                                        <Dialog
                                            open={openFilterPopup}
                                            onClose={handleClose}
                                            aria-labelledby="responsive-dialog-title"
                                        >
                                            <DialogTitle id="responsive-dialog-title">
                                                Filter
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="model_content">
                                                    <form onSubmit={filterFormik.handleSubmit}>
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <InputLabel id="demo-simple-select-label"
                                                                            className="ml-4 mt-3"
                                                                            style={{
                                                                                overflow: "visible",
                                                                                textAlign: "left"
                                                                            }}>Player Position:</InputLabel>
                                                                <FormControl fullWidth className="mt-2">
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name="player_position_id"
                                                                        label="Player Position"
                                                                        variant="outlined"
                                                                        onChange={(e) => {
                                                                            filterFormik.handleChange(e);
                                                                            filterInputHandler(e);
                                                                        }}
                                                                        // style={{minWidth: 100}}
                                                                        error={filterFormik.touched.player_position_id && Boolean(filterFormik.errors.player_position_id)}
                                                                        helperText={filterFormik.touched.player_position_id && filterFormik.errors.player_position_id}
                                                                    >
                                                                        {
                                                                            playerPositions.map((playerPosition) =>
                                                                                <MenuItem value={playerPosition.id}>
                                                                                    {playerPosition.position_name}
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-12 ">
                                                                <InputLabel id="assessment-category-label"
                                                                            className="ml-4 mt-3"
                                                                            style={{
                                                                                overflow: "visible",
                                                                                textAlign: "left"
                                                                            }}>Assessment Skill:</InputLabel>
                                                                <FormControl fullWidth className="mt-2">
                                                                    <Select
                                                                        labelId="assessment-category-label"
                                                                        id="demo-simple-select"
                                                                        name="category_id"
                                                                        label="Assessment Skill"
                                                                        variant="outlined"
                                                                        onChange={(e) => {
                                                                            filterFormik.handleChange(e);
                                                                            filterInputHandler(e);
                                                                        }}
                                                                        // style={{minWidth: 100}}
                                                                        error={filterFormik.touched.category_id && Boolean(filterFormik.errors.category_id)}
                                                                        helperText={filterFormik.touched.category_id && filterFormik.errors.category_id}
                                                                    >
                                                                        {
                                                                            assessmentCategories.map((assessmentSkill) =>
                                                                                <MenuItem value={assessmentSkill.id}>
                                                                                    {assessmentSkill.category_name}
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-md-12">
                                                                <Button type="submit"
                                                                        className="mdc-button mdc-button--raised w-100"
                                                                        variant="outlined" disabled={loader}>
                                                                    {loader && (
                                                                        <FaSpinner/>
                                                                    )}
                                                                    {loader && <span>&nbsp;Loading...</span>}
                                                                    {!loader && <span>Filter Skill</span>}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </DialogContentText>
                                            </DialogContent>
                                        </Dialog>

                                        <Dialog
                                            open={openNewSkillPopup}
                                            onClose={handleClose}
                                            aria-labelledby="responsive-dialog-title"
                                        >
                                            <DialogTitle id="responsive-dialog-title">
                                                Add New Skill
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="model_content">
                                                    <form onSubmit={formik.handleSubmit}>
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <InputLabel id="demo-simple-select-label"
                                                                            className="ml-4 mt-3"
                                                                            style={{
                                                                                overflow: "visible",
                                                                                textAlign: "left"
                                                                            }}>Player Position:</InputLabel>
                                                                <FormControl fullWidth className="mt-2">
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name="player_position_id"
                                                                        label="Player Position"
                                                                        variant="outlined"
                                                                        onChange={(e) => {
                                                                            formik.handleChange(e);
                                                                            inputHandler(e);
                                                                        }}
                                                                        // style={{minWidth: 100}}
                                                                        error={formik.touched.player_position_id && Boolean(formik.errors.player_position_id)}
                                                                        helperText={formik.touched.player_position_id && formik.errors.player_position_id}
                                                                    >
                                                                        {
                                                                            playerPositions.map((playerPosition) =>
                                                                                <MenuItem value={playerPosition.id}>
                                                                                    {playerPosition.position_name}
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-12 ">
                                                                <InputLabel id="assessment-category-label"
                                                                            className="ml-4 mt-3"
                                                                            style={{
                                                                                overflow: "visible",
                                                                                textAlign: "left"
                                                                            }}>Assessment Skill:</InputLabel>
                                                                <FormControl fullWidth className="mt-2">
                                                                    <Select
                                                                        labelId="assessment-category-label"
                                                                        id="demo-simple-select"
                                                                        name="category_id"
                                                                        label="Assessment Skill"
                                                                        variant="outlined"
                                                                        onChange={(e) => {
                                                                            formik.handleChange(e);
                                                                            inputHandler(e);
                                                                        }}
                                                                        // style={{minWidth: 100}}
                                                                        error={formik.touched.category_id && Boolean(formik.errors.category_id)}
                                                                        helperText={formik.touched.category_id && formik.errors.category_id}
                                                                    >
                                                                        {
                                                                            assessmentCategories.map((assessmentSkill) =>
                                                                                <MenuItem value={assessmentSkill.id}>
                                                                                    {assessmentSkill.category_name}
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-6 mt-5">
                                                                <TextField id="outlined-basic"
                                                                           className="mdc-text-field__input"
                                                                           name="skill_name"
                                                                           label="Skill Name"
                                                                           variant="outlined"
                                                                           InputLabelProps={{shrink: true}}
                                                                           onChange={(e) => {
                                                                               formik.handleChange(e);
                                                                               inputHandler(e);
                                                                           }}
                                                                           value={formik.values.skill_name}
                                                                           error={formik.touched.skill_name && Boolean(formik.errors.skill_name)}
                                                                           helperText={formik.touched.skill_name && formik.errors.skill_name}
                                                                />
                                                            </div>
                                                            <div className="col-6 mt-5">
                                                                <TextField id="outlined-basic"
                                                                           className="mdc-text-field__input"
                                                                           name="skill_info"
                                                                           label="Skill Info"
                                                                           variant="outlined"
                                                                           InputLabelProps={{shrink: true}}
                                                                           onChange={(e) => {
                                                                               formik.handleChange(e);
                                                                               inputHandler(e);
                                                                           }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-md-12">
                                                                <Button type="submit"
                                                                        className="mdc-button mdc-button--raised w-100"
                                                                        variant="outlined" disabled={loader}>
                                                                    {loader && (
                                                                        <FaSpinner/>
                                                                    )}
                                                                    {loader && <span>&nbsp;Loading...</span>}
                                                                    {!loader && <span>Save Skill</span>}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </DialogContentText>
                                            </DialogContent>
                                        </Dialog>

                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={15}
                                            autoHeight
                                            pagination
                                            rowCount={totalRows}
                                            rowsPerPageOptions={[15]}
                                            paginationMode="server"
                                            onPageChange={(newPage) => setPage(newPage)}
                                            loading={loader}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>
};

export default AssessmentSkill;

