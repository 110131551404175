import React, {useEffect, useState} from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {DataGrid} from "@material-ui/data-grid";
import {getRequest} from "../../utils/APICall";
import {GET_ALL_USER_TYPES} from "../../Constants";
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import {NavLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const UserTypes = (props) => {
    const classes = useStyles();

    const [model, setModel] = useState([]);
    const [open, setOpen] = useState(false);
    const [userType, setUserType] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
         getRequest(props, GET_ALL_USER_TYPES).then((response) => {
             setUserType(response.data);
             setLoader(false);
         });
    }, [props]);

    const showAssignTypes = (assignTypes) => {
        setModel(assignTypes);
        handleClickOpen(true);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const columns = [
        {field: "id", headerName: "ID", width: 90},
        {
            field: "typeName",
            headerName: "Type Name",
            width: 300
        },
        {
            field: "parent_name",
            headerName: "Parent Types name",
            width: 300
        },
        {
            field: "allow_modules",
            headerName: "Allow Modules",
            width: 300,
            renderCell: (state) => {
                const allowedType = state.row.allow_modules;
                return (<FormControl className={classes.formControl}>
                    <button className="btn btn-info" style={{fontSize: "small"}}
                            onClick={() => showAssignTypes(allowedType)}>
                        View Assigned Modules
                    </button>
                </FormControl>)
            },
        },
        {
            field: "changePermission",
            headerName: "Change Permission",
            width: 200,
            renderCell: (state) => {
                return (<NavLink exact className="mdc-drawer-link" to={
                    {
                        pathname: "/change-permission",
                        aboutProps: {
                            type: state.row.id
                        }
                    }
                }>
                    <span className="material-icons">
                        settings
                    </span>
                    Change Permission
                </NavLink>)
            },
        }
    ];

    const rows = [];
    if (userType.length > 0) {
        userType.map((value) => {
            rows.push({
                "id": value.id,
                "typeName": value.type_name,
                "parent_name": (value.parent_type) ? value.parent_type.type_name : "-",
                "allow_modules": (value.allow_modules) ? value.allow_modules : "-",
                "changePermission": value.status
            });
            return value;
        });
    }

    return <>
        <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Sidebar/>
        <div className="main-wrapper mdc-drawer-app-content">
            <div className="page-wrapper mdc-toolbar-fixed-adjust">
                <Header/>
                <main className="content-wrapper">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell stretch-card mdc-layout-grid__cell--span-12">
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <DialogTitle id="responsive-dialog-title">
                                        User Type having access
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="model_content">
                                            <div className="row mt-2" style={{
                                                display: "flex",
                                                justifyContent: 'center',
                                                textAlign: "justify"
                                            }}>
                                                {(!model.length) ? "Currently, This user type does not has any access" : model.map((m, i) => (
                                                    <div className="col-12">
                                                        {(i + 1) + " - " + m.module_name + " - (/" + m.api_route + ")"}
                                                    </div>
                                                ))}
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                                <div className="mdc-card">

                                    <h6 className="card-title card-padding pb-0">User Types</h6>

                                    <div className="table-responsive">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            autoHeight
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </>;
};
export default UserTypes;
